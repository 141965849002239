import React, { StrictMode } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@emotion/react';
import { it } from 'date-fns/locale/it';
import ReactDOM from 'react-dom/client';
import './index.css';
import CssBaseline from '@mui/material/CssBaseline';
import { LicenseInfo } from '@mui/x-data-grid-premium';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFnsV3';
import { MUIPremiumKey } from '@top-solution/microtecnica-mui';
import { CommonDataApiProvider, ZodLocalizationProvider, setAuthApiUrl } from '@top-solution/microtecnica-utils';
import { App } from './App';
import { AppNotifications } from './components/AppNotifications';
import { store } from './store/store';
import { theme } from './theme';

const appId = `${import.meta.env.VITE_APP_ID}`;
const appVersion = `${import.meta.env.VITE_VERSION}`;

LicenseInfo.setLicenseKey(MUIPremiumKey);

// eslint-disable-next-line
console.log(`🚀 ${appId} (v. ${appVersion}) 🚀`);

const authApi = window.COMMON_DATA_URL || `${import.meta.env.VITE_AUTH_API}`;
const apiURL = window.API_URL || `${import.meta.env.VITE_API_URL}`;

setAuthApiUrl(`${authApi}/api`);

window.console.log(apiURL);

ReactDOM.createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <CommonDataApiProvider value={{ appIDList: [appId], authApi }}>
      <ZodLocalizationProvider />
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={it}>
            <BrowserRouter>
              <HelmetProvider>
                <App />
                <AppNotifications />
              </HelmetProvider>
            </BrowserRouter>
          </LocalizationProvider>
        </ThemeProvider>
      </Provider>
    </CommonDataApiProvider>
  </StrictMode>
);

declare global {
  interface Window {
    COMMON_DATA_URL: string;
    API_URL: string;
  }
}
