import React from 'react';
import { indigo, teal } from '@mui/material/colors';
import Stack from '@mui/material/Stack';
import { AuthGuard } from '@top-solution/microtecnica-utils';
import { UnauthorizedPage } from '..';
import smsphoto from '../../assets/smsphoto.jpg';
import { AppSectionListRow } from '../../components/AppSectionListRow';
import { Layout } from '../../components/Layout';
import { useSectionList } from '../../hooks';

function HomePageComponent() {
  const { userSectionList, adminSectionList } = useSectionList();

  return (
    <AuthGuard unauthorizedFallback={<UnauthorizedPage />}>
      <Layout sx={{ padding: 2 }} disableGutters>
        <Stack
          sx={{
            gap: 2,
            flexDirection: 'column',
            '.MuiStack-root': {
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            },
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '12px' }}>
            <img src={smsphoto} alt="logo photo" width={'600px'} />
          </div>
          <AppSectionListRow list={userSectionList} color={indigo[500]} />
          {adminSectionList && <AppSectionListRow list={adminSectionList} color={teal[500]} />}
        </Stack>
      </Layout>
    </AuthGuard>
  );
}

export default React.memo(HomePageComponent);
