import React from 'react';
import { Controller, useForm } from 'react-hook-form';

import { zodResolver } from '@hookform/resolvers/zod';

import Alert from '@mui/material/Alert';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers-pro';
import AttachmentsField from '../../../components/Form/AttachmentField/AttachmentsField';
import { CompleteReportAddForm, REPORT_TYPE } from '../../../entities/Report';
import AlreadyReportedSelect from './AlreadyReportedSelect';
import CategorySelect from './CategorySelect';
import { InfoTooltipType } from './InfoTooltipType';
import RiskSeveritySelect from './RiskSeveritySelect';
import { useReportAddFormSchemaWithCategoryCheck } from './useReportAddFormSchema';

const defaultValues = {
  title: '',
  associatedRiskDescription: '',
  categoryId: null,
  description: '',
  membershipFunction: '',
  reportingDate: new Date(),
  riskSeverity: null,
  alreadyReported: null,
  isPublic: true,
  isAnonymous: false,
  isCustomerImpact: null,
  attachmentList: [],
  reportType: '',
  potentialRiskElimination: '',
  eventLocation: '',
} as unknown as CompleteReportAddForm;

export default function CompleteReportForm(props: {
  onSubmit: (values: CompleteReportAddForm) => void;
  initialValues?: CompleteReportAddForm;
}): React.ReactElement {
  const { onSubmit, initialValues } = props;
  const { ReportAddFormSchemaWithCategoryCheck } = useReportAddFormSchemaWithCategoryCheck(true);
  const { control, handleSubmit, watch, setValue } = useForm<CompleteReportAddForm>({
    defaultValues: initialValues ?? defaultValues,
    resolver: zodResolver(ReportAddFormSchemaWithCategoryCheck),
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="form" noValidate>
      <Stack gap={2} marginTop={4} marginBottom={4}>
        <Stack sx={{ gap: 1 }} direction={'row'} minHeight={50}>
          <Controller
            control={control}
            name="isPublic"
            render={({ field: { value, onChange } }) => (
              <FormControlLabel
                control={
                  <Switch
                    checked={!value}
                    onChange={(e, v) => {
                      onChange(!v);
                    }}
                  />
                }
                label="Segnalazione Privata"
              />
            )}
          />
          <Controller
            control={control}
            name="isAnonymous"
            render={({ field: { value, onChange } }) => (
              <>
                <FormControlLabel
                  control={<Switch checked={value} onChange={onChange} inputProps={{ 'aria-label': 'controlled' }} />}
                  label="Segnalazione Anonima"
                />
                {watch('isAnonymous') && (
                  <Alert severity="warning">Una segnalazione anonima non potrà più essere modificata</Alert>
                )}
              </>
            )}
          />
        </Stack>
        <Stack sx={{ gap: 1 }} direction={'row'}>
          <Controller
            control={control}
            name="reportType"
            render={({ field: { onChange, ...fieldProps }, fieldState: { error } }) => (
              <div style={{ display: 'flex', alignItems: 'center', width: '50%' }}>
                <TextField
                  select
                  label="Segnalazione Tipologia"
                  variant="outlined"
                  onChange={(e) => {
                    onChange(e);
                    setValue('reportingDate', new Date());
                    setValue('eventLocation', '');
                    setValue('isCustomerImpact', null);
                  }}
                  {...fieldProps}
                  error={Boolean(error)}
                  helperText={error?.message || ' '}
                  required
                  sx={{ width: '100%' }}
                >
                  <MenuItem value={REPORT_TYPE.EVENT}>{REPORT_TYPE.EVENT}</MenuItem>
                  <MenuItem value={REPORT_TYPE.HAZARD}>{REPORT_TYPE.HAZARD}</MenuItem>
                  <MenuItem value={REPORT_TYPE.CHANGE}>{REPORT_TYPE.CHANGE}</MenuItem>
                  <MenuItem value={REPORT_TYPE.ERROR}>{REPORT_TYPE.ERROR}</MenuItem>
                </TextField>
                <InfoTooltipType sx={{ ml: 1, mb: 2 }} />
              </div>
            )}
          />
        </Stack>
        {watch('reportType') === REPORT_TYPE.EVENT && (
          <Stack sx={{ gap: 1 }} direction={'row'}>
            <Controller
              control={control}
              name="isCustomerImpact"
              render={({ field: { onChange, ...fieldProps }, fieldState: { error } }) => (
                <TextField
                  select
                  label="Impatto sul cliente"
                  variant="outlined"
                  onChange={(e) => {
                    onChange(e);
                  }}
                  {...fieldProps}
                  error={Boolean(error)}
                  helperText={error?.message || ' '}
                  required
                  sx={{ width: '25%' }}
                >
                  <MenuItem value={'true'}>Si (Parte consegnata)</MenuItem>
                  <MenuItem value={'false'}>No (Mancato Evento)</MenuItem>
                  <MenuItem value={'unknown'}>Non so</MenuItem>
                </TextField>
              )}
            />

            <Controller
              control={control}
              name="eventLocation"
              render={({ field, fieldState: { error } }) => (
                <TextField
                  label="Luogo evento"
                  error={Boolean(error)}
                  helperText={error?.message || ' '}
                  sx={{ flex: 1 }}
                  {...field}
                  required
                />
              )}
            />
          </Stack>
        )}
        <Stack sx={{ gap: 1 }} direction={'row'}>
          <Controller
            control={control}
            name="title"
            render={({ field, fieldState: { error } }) => (
              <TextField
                label="Titolo"
                error={Boolean(error)}
                helperText={error?.message || ' '}
                {...field}
                required
                sx={{ flex: 1 }}
              />
            )}
          />
          <Controller
            name="reportingDate"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <DatePicker
                sx={{ mb: 1 }}
                label="Data Evento/Pericolo"
                disablePast={watch('reportType') === REPORT_TYPE.HAZARD}
                {...field}
                slotProps={{
                  textField: {
                    error: Boolean(error),
                    helperText: error?.message,
                    required: true,
                  },
                }}
                disableFuture
              />
            )}
          />
        </Stack>
        <Controller
          control={control}
          name="membershipFunction"
          render={({ field, fieldState: { error } }) => (
            <TextField
              label="Funzione di appartenenza"
              error={Boolean(error)}
              helperText={error?.message || ' '}
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="categoryId"
          render={({ field: { onChange, ...fieldProps }, fieldState: { error } }) => (
            <CategorySelect
              error={Boolean(error)}
              errorText={error?.message || ' '}
              onChange={(e, v) => onChange(v)}
              {...fieldProps}
            />
          )}
        />
        <Controller
          control={control}
          name="description"
          render={({ field, fieldState: { error } }) => (
            <TextField
              label="Descrizione"
              error={Boolean(error)}
              helperText={error?.message || ' '}
              {...field}
              required
              multiline
            />
          )}
        />
        <Controller
          name="attachmentList"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <AttachmentsField
              {...field}
              value={field.value ?? []}
              sx={{ flex: 1 }}
              label="Allegati a descrizione"
              error={Boolean(error)}
              helperText={error?.message ?? ' '}
            />
          )}
        />
        <Stack sx={{ gap: 1 }} direction={'row'}>
          <Controller
            control={control}
            name="potentialRiskElimination"
            render={({ field, fieldState: { error } }) => (
              <TextField
                label="Contromisure già intraprese e suggerimenti per eliminazione rischio potenziale"
                error={Boolean(error)}
                helperText={error?.message || ' '}
                {...field}
                sx={{ flex: 1 }}
                required
              />
            )}
          />
        </Stack>
        <Controller
          control={control}
          name="associatedRiskDescription"
          render={({ field, fieldState: { error } }) => (
            <TextField
              label="Severità rischio evento"
              error={Boolean(error)}
              helperText={
                error?.message || 'Peggior evento che può verificarsi, eventualmente compilare con SO/SRM/SKP/FSP'
              }
              {...field}
            />
          )}
        />
        <Stack sx={{ gap: 1 }} direction={'row'}>
          <Controller
            control={control}
            name="alreadyReported"
            render={({ field: { onChange, ...field }, fieldState: { error } }) => (
              <AlreadyReportedSelect
                onChange={(e, v) => onChange(v)}
                error={Boolean(error)}
                helperText={error?.message || ' '}
                {...field}
                sx={{ width: '50%' }}
              />
            )}
          />
          <Controller
            control={control}
            name="riskSeverity"
            render={({ field: { onChange, ...field }, fieldState: { error } }) => (
              <RiskSeveritySelect
                error={Boolean(error)}
                helperText={error?.message || 'Peggiore evento che ragionevolmente può verificarsi'}
                sx={{ width: '50%' }}
                label={'Severità rischio evento'}
                {...field}
                onChange={(e, v) => onChange(v)}
              />
            )}
          />
        </Stack>
        <Controller
          control={control}
          name="flagTechData"
          render={({ field, fieldState: { error } }) => (
            <FormControl required error={Boolean(error)}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={Boolean(field.value)}
                    onChange={(_, checked) => {
                      field.onChange(checked);
                    }}
                  />
                }
                label="Confermo che questo report non contiene nessun dato tecnico"
              />
              <FormHelperText>{error?.message || ' '}</FormHelperText>
            </FormControl>
          )}
        />
      </Stack>
    </form>
  );
}
