import Skeleton from '@mui/material/Skeleton';
import { useReadUserQuery } from '@top-solution/microtecnica-utils';

export function UserName({ username }: { username: string }): JSX.Element {
  const readUser = useReadUserQuery({ username });

  if (readUser.isLoading) {
    return <Skeleton variant="text" width="1ch" />;
  }

  if (readUser.data?.lastname) {
    return (
      <>
        {readUser.data.lastname} {readUser.data.firstname}
      </>
    );
  }

  return <>{username}</>;
}
