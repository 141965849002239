import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { ArrowDropDownIcon } from '@mui/x-date-pickers-pro';
import { ReportType } from '../../entities/Report';
import { ReportStatusDialog } from './ChooseStatusDialog/ReportStatusDialog';
import { ReportDeleteConfirm } from './ReportDeleteConfirm';

function ReportMenuComponent(props: { report: ReportType }) {
  const { report } = props;
  const navigate = useNavigate();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<ArrowDropDownIcon />}
      >
        {'Gestisci report'}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem
          onClick={() => {
            setDeleteDialogOpen(true);
            handleClose();
          }}
        >
          {'Elimina report'}
        </MenuItem>
        <MenuItem
          onClick={() => {
            setUpdateDialogOpen(true);
            handleClose();
          }}
        >
          {'Cambia status report'}
        </MenuItem>
      </Menu>
      <ReportDeleteConfirm
        open={deleteDialogOpen}
        onClose={() => {
          setDeleteDialogOpen(false);
        }}
        onConfirm={() => navigate('/reports')}
        data={report}
      />
      <ReportStatusDialog
        open={updateDialogOpen}
        onClose={() => {
          setUpdateDialogOpen(false);
        }}
        report={report}
      />
    </div>
  );
}

export const ReportMenu = React.memo(ReportMenuComponent);
