import React, { useMemo } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import {
  DataGridPremium,
  GridColDef,
  GridRowModel,
  GridValueFormatterParams,
  GridValueGetterParams,
} from '@mui/x-data-grid-premium';
import { DataGridWrapper } from '@top-solution/microtecnica-mui/src/components/DataGrid';
import { userColumn } from '../../components/DataGrid/userColumn';
import { ReportHistory } from '../../entities/Report';
import { useReadReportHistoryByIdQuery } from '../../services/reportApi';
import { nonEmptyGridDateOperators } from '../../utils/datagrid';
import { formatDateTime } from '../../utils/dates';
import { useReportStatusDataGridColumn } from './useReportStatusDataGridColumn';

function ReportHistoryDataGridComponent(props: { id: number }) {
  const { id } = props;
  const { data: history, isLoading } = useReadReportHistoryByIdQuery(id);
  const statusColumn = useReportStatusDataGridColumn();

  const columns = useMemo<GridColDef<ReportHistory>[]>(
    () => [
      { ...statusColumn },
      {
        field: 'fromDate',
        headerName: 'Data aggiornamento',
        type: 'date',
        valueGetter: ({ value }: GridValueGetterParams<GridRowModel, string>) => value && new Date(value),
        valueFormatter: ({ value }: GridValueFormatterParams<Date>) => value && formatDateTime(value),
        width: 140,
        filterOperators: nonEmptyGridDateOperators(),
      },
      {
        ...userColumn,
        headerName: 'Riportato da',
        width: 200,
      },
      { field: 'notes', headerName: 'Commenti', flex: 1 },
    ],
    [statusColumn]
  );

  return (
    <Card>
      <CardHeader
        title={
          <Typography component="h2" variant="h6">
            Storico Movimentazione
          </Typography>
        }
      />
      <CardContent>
        <DataGridWrapper offsetHeight={570}>
          <DataGridPremium
            rows={history ?? []}
            columns={columns}
            loading={isLoading}
            pagination={true}
            disableRowSelectionOnClick
            disableAggregation
            disableRowGrouping
            initialState={{
              sorting: {
                sortModel: [{ field: 'fromDate', sort: 'desc' }],
              },
            }}
          />
        </DataGridWrapper>
      </CardContent>
    </Card>
  );
}

export const ReportHistoryDataGrid = React.memo(ReportHistoryDataGridComponent);
