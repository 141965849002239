import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { closeNotification, pushNotification } from '../store/notificationsSlice';

export function useNotifications() {
  const dispatch = useDispatch();
  const uiPushNotification = useCallback(
    (message: string) => {
      dispatch(pushNotification({ message }));
      setTimeout(() => {
        dispatch(closeNotification({ index: 0 }));
      }, 3000);
    },
    [dispatch]
  );

  return useMemo(() => ({ pushNotification: uiPushNotification }), [uiPushNotification]);
}
