import React from 'react';
import Account from '@mdi/svg/svg/account.svg?react';
import Alert from '@mdi/svg/svg/alert.svg?react';
import ArchiveCog from '@mdi/svg/svg/archive-cog.svg?react';
import Archive from '@mdi/svg/svg/archive.svg?react';
import BioHazard from '@mdi/svg/svg/biohazard.svg?react';
import CashMultiple from '@mdi/svg/svg/cash-multiple.svg?react';
import CheckCircleOutline from '@mdi/svg/svg/check-circle-outline.svg?react';
import ChevronDown from '@mdi/svg/svg/chevron-down.svg?react';
import ChevronLeft from '@mdi/svg/svg/chevron-left.svg?react';
import ChevronRight from '@mdi/svg/svg/chevron-right.svg?react';
import ChevronUp from '@mdi/svg/svg/chevron-up.svg?react';
import Close from '@mdi/svg/svg/close.svg?react';
import ContentDuplicate from '@mdi/svg/svg/content-duplicate.svg?react';
import ContentSave from '@mdi/svg/svg/content-save.svg?react';
import ImportData from '@mdi/svg/svg/database-import.svg?react';
import Earth from '@mdi/svg/svg/earth.svg?react';
import EmailFast from '@mdi/svg/svg/email-fast.svg?react';
import Eye from '@mdi/svg/svg/eye.svg?react';
import ExcelFile from '@mdi/svg/svg/file-excel.svg?react';
import Upload from '@mdi/svg/svg/file-upload.svg?react';
import File from '@mdi/svg/svg/file.svg?react';
import InformationOutline from '@mdi/svg/svg/information-outline.svg?react';
import Search from '@mdi/svg/svg/magnify.svg?react';
import Message from '@mdi/svg/svg/message.svg?react';
import NoteEdit from '@mdi/svg/svg/note-edit.svg?react';
import ExternalUrl from '@mdi/svg/svg/open-in-new.svg?react';
import Pencil from '@mdi/svg/svg/pencil.svg?react';
import Add from '@mdi/svg/svg/plus-circle.svg?react';
import StateMachine from '@mdi/svg/svg/state-machine.svg?react';
import TagTextOutline from '@mdi/svg/svg/tag-text-outline.svg?react';
import TextBoxEditOutline from '@mdi/svg/svg/text-box-edit-outline.svg?react';
import Details from '@mdi/svg/svg/text-box-search.svg?react';
import TrashCan from '@mdi/svg/svg/trash-can.svg?react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const createIcon = (icon: React.ReactNode) =>
  function Icon(props: SvgIconProps): JSX.Element {
    return <SvgIcon {...props}>{icon}</SvgIcon>;
  };

export const AddIcon = createIcon(<Add />);
export const AlertIcon = createIcon(<Alert />);
export const ArchiveCogIcon = createIcon(<ArchiveCog />);
export const ArchiveIcon = createIcon(<Archive />);
export const CancelIcon = createIcon(<Close />);
export const CashMultipleIcon = createIcon(<CashMultiple />);
export const CheckCircleOutlineIcon = createIcon(<CheckCircleOutline />);
export const ChevronDownIcon = createIcon(<ChevronDown />);
export const ChevronLeftIcon = createIcon(<ChevronLeft />);
export const ChevronRightIcon = createIcon(<ChevronRight />);
export const ChevronUpIcon = createIcon(<ChevronUp />);
export const DeleteIcon = createIcon(<TrashCan />);
export const DuplicateIcon = createIcon(<ContentDuplicate />);
export const EarthIcon = createIcon(<Earth />);
export const EditIcon = createIcon(<Pencil />);
export const ExcelFileIcon = createIcon(<ExcelFile />);
export const ExternalUrlIcon = createIcon(<ExternalUrl />);
export const EyeIcon = createIcon(<Eye />);
export const FileIcon = createIcon(<File />);
export const ImportDataIcon = createIcon(<ImportData />);
export const InformationOutlineIcon = createIcon(<InformationOutline />);
export const MessageIcon = createIcon(<Message />);
export const SaveIcon = createIcon(<ContentSave />);
export const SearchIcon = createIcon(<Search />);
export const TagTextOutlineIcon = createIcon(<TagTextOutline />);
export const TextBoxEditOutlineIcon = createIcon(<TextBoxEditOutline />);
export const UploadIcon = createIcon(<Upload />);
export const UserIcon = createIcon(<Account />);
export const StateMachineIcon = createIcon(<StateMachine />);
export const DetailsIcon = createIcon(<Details />);
export const EmailFastIcon = createIcon(<EmailFast />);
export const NoteEditIcon = createIcon(<NoteEdit />);
export const BioHazardIcon = createIcon(<BioHazard />);
