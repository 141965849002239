import {
  createPagedResponseSchema,
  formatQueryParams,
  PagedRequestParams,
  PagedResponse,
} from '@top-solution/microtecnica-utils';

import { CategoryResponse, CategoryResponseSchema } from '../entities/Category';
import {
  CreateReportAddForm,
  ReportHistory,
  ReportHistorySchema,
  ReportSchema,
  ReportType,
  UpdateReportStatus,
} from '../entities/Report';
import { api, TAG_TYPES } from './baseApi';

const url = 'reports';

const reportApi = api.injectEndpoints({
  endpoints: (builder) => ({
    readReportList: builder.query<PagedResponse<ReportType>, PagedRequestParams>({
      query: (params) => ({ url, params: formatQueryParams(params) }),
      transformResponse: (data) => createPagedResponseSchema(ReportSchema).parse(data),
      providesTags: () => [{ type: TAG_TYPES.REPORT, id: 'LIST' }],
    }),
    readReportById: builder.query<ReportType, ReportType['id']>({
      query: (id) => `${url}?id=eq%3A${id}`,
      transformResponse: (data) => createPagedResponseSchema(ReportSchema).parse(data).data[0],
      providesTags: (response, error, id) => [{ type: TAG_TYPES.REPORT, id }],
    }),
    readReportHistoryById: builder.query<ReportHistory[], ReportType['id']>({
      query: (id) => `${url}-history/${id}`,
      transformResponse: (data) => ReportHistorySchema.array().parse(data),
      providesTags: (response, error, id) => [{ type: TAG_TYPES.REPORT, id }],
    }),
    createReport: builder.mutation<ReportType['id'], CreateReportAddForm>({
      query: (body) => ({
        url,
        method: 'POST',
        body,
      }),
      invalidatesTags: () => [{ type: TAG_TYPES.REPORT, id: 'LIST' }],
    }),
    updateStatus: builder.mutation<ReportType['id'], UpdateReportStatus & { id: ReportType['id'] }>({
      query: ({ id, ...body }) => ({
        url: `${url}/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (response, error, { id }) => [
        { type: TAG_TYPES.REPORT, id: 'LIST' },
        { type: TAG_TYPES.REPORT, id },
      ],
    }),
    readReportTaxonomy: builder.query<CategoryResponse, void>({
      query: () => `taxonomy`,
      transformResponse: (data) => CategoryResponseSchema.parse(data),
      providesTags: () => [{ type: TAG_TYPES.REPORT, id: 'TAXONOMY_LIST' }],
    }),
    updateReport: builder.mutation<void, CreateReportAddForm & { id: ReportType['id'] }>({
      query: ({ id, ...body }) => ({
        url: `${url}/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (response, error, { id }) => {
        const tags = [
          { type: TAG_TYPES.REPORT, id: 'LIST' },
          { type: TAG_TYPES.REPORT, id },
        ];

        return tags;
      },
    }),
    removeReport: builder.mutation<void, ReportType['id']>({
      query: (id) => ({ url: `${url}/${id}`, method: 'DELETE' }),
      invalidatesTags: () => [{ type: TAG_TYPES.REPORT, id: 'LIST' }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useReadReportListQuery,
  useLazyReadReportListQuery,
  useReadReportByIdQuery,
  useReadReportTaxonomyQuery,
  useReadReportHistoryByIdQuery,
  useCreateReportMutation,
  useUpdateStatusMutation,
  useRemoveReportMutation,
  useUpdateReportMutation,
} = reportApi;
