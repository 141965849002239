// import blueGrey from '@mui/material/colors/blueGrey';
import { red } from '@mui/material/colors';
import grey from '@mui/material/colors/grey';
import { itIT as coreLocale } from '@mui/material/locale';
import { createTheme, darken, getContrastRatio, lighten, ThemeOptions } from '@mui/material/styles';
import { itIT as dataGridLocale } from '@mui/x-data-grid-premium';
import { itIT as datePickersLocale } from '@mui/x-date-pickers-pro';

const primaryBase = '#0A56CC';
const secondaryBase = '#4A677D';
const accentBase = '#9C27B0';

const baseThemeConfig: ThemeOptions = {
  palette: {
    primary: {
      main: primaryBase,
      light: lighten(primaryBase, 0.2),
      dark: darken(primaryBase, 0.2),
      contrastText: getContrastRatio(primaryBase, '#fff') > 4.5 ? '#fff' : '#111',
    },
    secondary: {
      main: secondaryBase,
      light: lighten(secondaryBase, 0.2),
      dark: darken(secondaryBase, 0.2),
      contrastText: getContrastRatio(secondaryBase, '#fff') > 4.5 ? '#fff' : '#111',
    },
    accent: {
      main: accentBase,
      light: lighten(accentBase, 0.2),
      dark: darken(accentBase, 0.2),
      contrastText: getContrastRatio(accentBase, '#fff') > 4.5 ? '#fff' : '#111',
    },
    background: {
      default: grey[50],
    },
    error: {
      main: red[400],
    },
  },
  components: {
    MuiPaper: {
      defaultProps: {
        variant: 'outlined',
      },
    },
  },
};

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    accent: Palette['primary'];
  }
  interface PaletteOptions {
    accent: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    accent: true;
  }
}

export const theme = createTheme(baseThemeConfig, datePickersLocale, dataGridLocale, coreLocale);
