import React from 'react';
import { Controller, useForm } from 'react-hook-form';

import { zodResolver } from '@hookform/resolvers/zod';

import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { QuickReportAddForm } from '../../../entities/Report';
import AlreadyReportedSelect from './AlreadyReportedSelect';
import CategorySelect from './CategorySelect';
import RiskSeveritySelect from './RiskSeveritySelect';
import { useReportAddFormSchemaWithCategoryCheck } from './useReportAddFormSchema';

const defaultValues = {
  title: '',
  associatedRiskDescription: '',
  categoryId: null,
  description: '',
  membershipFunction: '',
  reportingDate: new Date(),
  riskMitigation: '',
  riskSeverity: null,
  alreadyReported: null,
} as unknown as QuickReportAddForm;

export default function QuickReportForm(props: {
  onSubmit: (values: QuickReportAddForm) => void;
  initialValues?: QuickReportAddForm;
}): React.ReactElement {
  const { onSubmit, initialValues } = props;

  const { ReportAddFormSchemaWithCategoryCheck } = useReportAddFormSchemaWithCategoryCheck(false);
  const { control, handleSubmit } = useForm<QuickReportAddForm>({
    defaultValues: initialValues ?? defaultValues,
    resolver: zodResolver(ReportAddFormSchemaWithCategoryCheck),
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="form" noValidate>
      <Stack gap={2} marginTop={4} marginBottom={4}>
        <Stack sx={{ gap: 1 }} direction={'row'}>
          <Controller
            control={control}
            name="title"
            render={({ field, fieldState: { error } }) => (
              <TextField
                label="Titolo"
                error={Boolean(error)}
                helperText={error?.message || ' '}
                sx={{ flex: 1 }}
                {...field}
                required
              />
            )}
          />
          <Controller
            name="reportingDate"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <DatePicker
                label="Data Evento/Pericolo"
                {...field}
                slotProps={{
                  textField: {
                    error: Boolean(error),
                    helperText: error?.message,
                  },
                }}
                disableFuture
              />
            )}
          />
        </Stack>
        <Controller
          control={control}
          name="membershipFunction"
          render={({ field, fieldState: { error } }) => (
            <TextField
              label="Funzione di appartenenza"
              error={Boolean(error)}
              helperText={error?.message || ' '}
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="description"
          render={({ field, fieldState: { error } }) => (
            <TextField
              label="Descrizione"
              error={Boolean(error)}
              helperText={error?.message || ' '}
              {...field}
              multiline
              required
            />
          )}
        />
        <Controller
          control={control}
          name="associatedRiskDescription"
          render={({ field, fieldState: { error } }) => (
            <TextField
              label="Descrivere rischio associato"
              error={Boolean(error)}
              helperText={error?.message || ' '}
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="categoryId"
          render={({ field: { onChange, ...fieldProps }, fieldState: { error } }) => (
            <CategorySelect
              error={Boolean(error)}
              errorText={error?.message || ' '}
              onChange={(e, v) => onChange(v)}
              {...fieldProps}
            />
          )}
        />
        <Stack sx={{ gap: 1 }} direction={'row'}>
          <Controller
            control={control}
            name="alreadyReported"
            render={({ field: { onChange, ...field }, fieldState: { error } }) => (
              <AlreadyReportedSelect
                error={Boolean(error)}
                helperText={error?.message || ' '}
                onChange={(e, v) => onChange(v)}
                sx={{ width: '50%' }}
                {...field}
              />
            )}
          />
          <Controller
            control={control}
            name="riskSeverity"
            render={({ field: { onChange, ...field }, fieldState: { error } }) => (
              <RiskSeveritySelect
                error={Boolean(error)}
                helperText={error?.message || 'Peggiore evento che ragionevolmente può verificarsi'}
                sx={{ width: '50%' }}
                label={'Severità rischio evento'}
                {...field}
                onChange={(e, v) => onChange(v)}
              />
            )}
          />
        </Stack>
        <Controller
          control={control}
          name="riskMitigation"
          render={({ field, fieldState: { error } }) => (
            <TextField
              label="Contromisure già intraprese e suggerimenti per mitigazione rischio"
              error={Boolean(error)}
              helperText={error?.message || ' '}
              {...field}
              required
            />
          )}
        />
        <Controller
          control={control}
          name="flagTechData"
          render={({ field, fieldState: { error } }) => (
            <FormControl required error={Boolean(error)}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={Boolean(field.value)}
                    onChange={(_, checked) => {
                      field.onChange(checked);
                    }}
                  />
                }
                label="Confermo che questo report non contiene nessun dato tecnico"
              />
              <FormHelperText>{error?.message || ' '}</FormHelperText>
            </FormControl>
          )}
        />
      </Stack>
    </form>
  );
}
