import React, { useCallback, useMemo } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { useReadReportTaxonomyQuery } from '../../../services/reportApi';

function CategorySelect(props: {
  onLvl1Change?: (event: React.ChangeEvent<HTMLInputElement>, id: number | null) => void;
  onLvl2Change?: (event: React.ChangeEvent<HTMLInputElement>, id: number | null) => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, categoryId: number | null) => void;
  value?: number | null;
  error?: boolean;
  errorText?: string;
}) {
  const { onChange, onLvl1Change, onLvl2Change, value, error, errorText } = props;
  const { data, isLoading, isError } = useReadReportTaxonomyQuery();

  const values = useMemo(() => {
    const lvl3Node = data?.first.find((l) => l.id === value);
    if (lvl3Node) {
      const lvl2Node = data?.second.find((l) => l.id === lvl3Node.parent?.id);
      return {
        lvl1: lvl2Node?.parent?.id,
        lvl2: lvl2Node?.id,
        lvl3: lvl3Node.id,
      };
    }
    const lvl2Node = data?.second.find((l) => l.id === value);
    if (lvl2Node)
      return {
        lvl1: lvl2Node.parent?.id,
        lvl2: lvl2Node.id,
        lvl3: null,
      };
    const lvl1Node = data?.third.find((l) => l.id === value);
    if (lvl1Node)
      return {
        lvl1: lvl1Node.id,
        lvl2: null,
        lvl3: null,
      };
    return {
      lvl1: null,
      lvl2: null,
      lvl3: null,
    };
  }, [data?.first, data?.second, data?.third, value]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, id: number | null) => {
      onChange(event, id);
    },
    [onChange]
  );

  if (isLoading)
    return (
      <Stack
        direction={'row'}
        sx={{
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Skeleton sx={{ width: '30%' }} />
        <Skeleton sx={{ width: '30%' }} />
        <Skeleton sx={{ width: '30%' }} />
      </Stack>
    );
  return (
    <Stack
      direction={'row'}
      sx={{
        width: '100%',
        justifyContent: 'space-between',
        '.MuiFormControl-root': { width: '30%' },
      }}
    >
      <TextField
        label="Tipologia Pericolo"
        variant="outlined"
        select
        onChange={(e) => {
          handleChange(e as React.ChangeEvent<HTMLInputElement>, Number(e.target.value));
          onLvl1Change && onLvl1Change(e as React.ChangeEvent<HTMLInputElement>, Number(e.target.value));
        }}
        value={values.lvl1}
        disabled={!!isError || isLoading}
        error={Boolean(error)}
        helperText={!values.lvl1 && errorText ? errorText : ' '}
        required
      >
        {data?.third.map((c) => (
          <MenuItem key={c.id} value={c.id}>
            {c.name}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        label="Categoria pericolo/errore"
        variant="outlined"
        select
        onChange={(e) => {
          handleChange(e as React.ChangeEvent<HTMLInputElement>, Number(e.target.value));
          onLvl2Change && onLvl2Change(e as React.ChangeEvent<HTMLInputElement>, Number(e.target.value));
        }}
        value={values.lvl2}
        disabled={
          !values.lvl1 ||
          !!isError ||
          isLoading ||
          (data?.second.filter((c) => c.parent?.id === values.lvl1).length ?? 0) < 1
        }
        required
        error={Boolean(error)}
        helperText={!values.lvl2 && errorText ? errorText : ' '}
      >
        {data?.second
          .filter((c) => c.parent?.id === values.lvl1)
          .map((c) => (
            <MenuItem key={c.id} value={c.id}>
              {c.name}
            </MenuItem>
          ))}
      </TextField>
      <TextField
        label="Tipologia evento"
        variant="outlined"
        select
        disabled={
          !values.lvl2 ||
          !!isError ||
          isLoading ||
          (data?.first.filter((c) => c.parent?.id === values.lvl2).length ?? 0) < 1
        }
        onChange={(v) => handleChange(v as React.ChangeEvent<HTMLInputElement>, Number(v.target.value))}
        value={values.lvl3}
        error={Boolean(error)}
        helperText={!values.lvl3 && errorText ? errorText : ' '}
      >
        {data?.first
          .filter((c) => c.parent?.id === values.lvl2)
          .map((c) => (
            <MenuItem key={c.id} value={c.id}>
              {c.name}
            </MenuItem>
          ))}
      </TextField>
    </Stack>
  );
}

export default React.forwardRef(CategorySelect);
