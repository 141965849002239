import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import { closeNotification, removeNotification, selectNotifications } from '../store/notificationsSlice';

function AppNotificationsComponent() {
  const snackbars = useSelector(selectNotifications);
  const dispatch = useDispatch();

  return (
    <div>
      {snackbars.map((snackbar, i) => {
        return (
          <Snackbar
            key={i}
            open={!snackbar.closing}
            message={snackbar.message}
            onClick={() => dispatch(closeNotification({ index: i }))}
            TransitionProps={{ onExited: () => dispatch(removeNotification({ index: i })) }}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          />
        );
      })}
    </div>
  );
}

export const AppNotifications = React.memo(AppNotificationsComponent);
