import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { blue, red } from '@mui/material/colors';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { darken } from '@mui/system';
import { AuthGuard, useAuth, useReadPlantsQuery } from '@top-solution/microtecnica-utils';
import BRU from '../../assets/BRU.png';
import LU from '../../assets/LU.png';
import SMS from '../../assets/sms.jpg';
import TO from '../../assets/TO.png';
import { EmailFastIcon, InformationOutlineIcon, NoteEditIcon } from '../../components/Icons';
import { Layout } from '../../components/Layout';
import UnauthorizedPage from '../Error/UnauthorizedPage';
import { notifyEventSection } from './sections';

const breadcrumbs = [{ title: notifyEventSection.title, url: notifyEventSection.path }];
export default function NotifyEvent(): React.ReactElement {
  const { isLoading, error, data } = useReadPlantsQuery();
  const [selectedPlantId, setSelectedPlantId] = useState<number | null>(null);
  const { token } = useAuth();
  const [downloadInstruction, setDownloadInstruction] = useState<File | null>(null);

  const plantImages = {
    BRU: BRU,
    LU: LU,
    TO: TO,
  };

  useEffect(() => {
    const fetchInstructionLink = async () => {
      try {
        const response = await fetch(`/api/v1/instructions`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const blob = await response.blob();

        // Extract the filename  from the Content-Disposition header
        const contentDisposition = response.headers.get('Content-Disposition');
        let fileName = 'fetchedFile';

        if (contentDisposition) {
          const match = contentDisposition.match(/filename="(.+)"/);
          if (match) {
            fileName = match[1];
          }
        }

        const fetchedFile = new File([blob], `${fileName}`, { type: blob.type });
        setDownloadInstruction(fetchedFile);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error fetching data:', error);
      }
    };

    fetchInstructionLink();
  }, [token]);

  return (
    <AuthGuard unauthorizedFallback={<UnauthorizedPage />}>
      <Layout
        title={notifyEventSection.title}
        inProgress={isLoading}
        error={error}
        disableGutters
        breadcrumbs={breadcrumbs}
      >
        <Accordion expanded={!selectedPlantId}>
          <AccordionSummary
            aria-controls="panel1-content"
            sx={{ '.MuiAccordionSummary-content': { justifyContent: 'center' } }}
            onClick={() => setSelectedPlantId(null)}
          >
            {!selectedPlantId ? (
              <Typography variant="h6">Seleziona Stabilimento</Typography>
            ) : (
              <Typography variant="h6">Stabilimento: {`${data?.map[selectedPlantId].name}`}</Typography>
            )}
          </AccordionSummary>
          <AccordionDetails>
            <Stack
              sx={{
                display: 'flex',
                flexFlow: 'row wrap',
                justifyContent: 'center',
                px: 2,
              }}
            >
              {data?.list.map((plant) => (
                <Button
                  key={plant.id}
                  size="large"
                  color="primary"
                  variant="outlined"
                  sx={{
                    width: 200,
                    height: 200,
                    margin: 1,
                    padding: 1,
                  }}
                  onClick={() => setSelectedPlantId(plant.id)}
                >
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                    <img
                      src={plantImages[plant.slug as keyof typeof plantImages]}
                      alt={plant.slug}
                      style={{ height: 90, width: 150, objectFit: 'scale-down', marginBottom: 8 }}
                    />
                    <span>{plant.name}</span>
                  </Box>
                </Button>
              ))}
            </Stack>
          </AccordionDetails>
        </Accordion>
        <Accordion disabled={!selectedPlantId} expanded={!!selectedPlantId}>
          <AccordionSummary
            aria-controls="panel2-content"
            id="panel2-header"
            sx={{ '.MuiAccordionSummary-content': { justifyContent: 'center' } }}
          >
            <Typography variant="h6" mr={1}>
              Seleziona tipo di report
            </Typography>
            {downloadInstruction && (
              <Button
                aria-label="info"
                variant="contained"
                color="info"
                size="small"
                disableElevation
                href={URL.createObjectURL(downloadInstruction)}
                download={downloadInstruction.name}
                sx={{
                  minWidth: 0,
                  width: 28,
                  height: 28,
                  padding: 0,
                  borderRadius: '50%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <InformationOutlineIcon sx={{ width: 24, height: 24 }} />
              </Button>
            )}
          </AccordionSummary>
          <AccordionDetails>
            <Stack
              direction={'row'}
              sx={{
                gap: 2,
                justifyContent: 'center',
                '.MuiButton-root': {
                  paddingX: 1,
                  paddingTop: 3,
                  width: 200,
                  height: 200,
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  textAlign: 'center',
                  lineHeight: 1.2,
                },
                '.MuiButton-startIcon': {
                  marginX: 0,
                  marginBottom: 2,

                  '.MuiSvgIcon-root': {
                    fontSize: 50,
                  },
                },
              }}
            >
              <Button
                component={Link}
                to={`/reports/create?plantId=${selectedPlantId}&complete=false`}
                startIcon={<EmailFastIcon />}
                variant="contained"
                sx={{
                  background: blue[700],

                  '&:hover': {
                    background: darken(blue[700], 0.1),
                  },
                }}
              >
                {'Segnalazione veloce (es. errore)'}
              </Button>
              <Button
                component={Link}
                to={`/reports/create?plantId=${selectedPlantId}&complete=true`}
                startIcon={<NoteEditIcon />}
                variant="contained"
                sx={{
                  background: red[700],

                  '&:hover': {
                    background: darken(red[700], 0.1),
                  },
                }}
              >
                {'Segnalazione completa (obbligatoria se EVENTO/MANCATO EVENTO/PERICOLO)'}
              </Button>
            </Stack>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '12px' }}>
              <img src={SMS} alt="logo safety" width={'300px'} />
            </div>
          </AccordionDetails>
        </Accordion>
      </Layout>
    </AuthGuard>
  );
}
