import { useAuth } from '@top-solution/microtecnica-utils';
import { UserIcon } from '../components/Icons';
import { AppSection } from '../entities/AppSection';
import { notifyEventSection, reportSection } from '../pages/Report/sections';

export function useSectionList(): { adminSectionList: AppSection[]; userSectionList: AppSection[] } {
  const { isAdmin } = useAuth();

  const userSectionList: AppSection[] = [reportSection, notifyEventSection];

  const adminSectionList: AppSection[] = isAdmin
    ? [
        {
          path: '/users',
          title: 'Gestione utenti',
          icon: <UserIcon />,
        },
      ]
    : [];

  return {
    adminSectionList,
    userSectionList,
  };
}
