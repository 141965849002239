import React from 'react';
import { AppUserListGrid } from '@top-solution/microtecnica-mui';
import { Layout } from '../../components/Layout';

const breadcrumbs = [{ title: 'Gestione Utenti' }];

export default function UserList(): React.ReactElement {
  return (
    <Layout sx={{ padding: 2 }} disableGutters breadcrumbs={breadcrumbs}>
      <AppUserListGrid appId={import.meta.env.VITE_APP_ID} />
    </Layout>
  );
}
