import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import Container, { ContainerProps } from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import { Breadcrumb, Header, MenuItem, ServerErrorBox } from '@top-solution/microtecnica-mui';
import { ApiError } from '@top-solution/microtecnica-utils';
import { useSectionList } from '../../hooks/useSectionList';
import FullPageProgress from './FullPageProgress';

interface LayoutProps extends ContainerProps {
  title?: string;
  children?: React.ReactNode;
  inProgress?: boolean;
  error?: ApiError | Error;
  breadcrumbs?: Breadcrumb[];
  fullHeight?: boolean;
}

const APP_NAME = import.meta.env.VITE_NAME as string;

function LayoutComponent(props: LayoutProps) {
  const { children, title, breadcrumbs, inProgress, error, sx, maxWidth, fullHeight, ...containerProps } = props;
  const sections = useSectionList();
  const navigate = useNavigate();

  const pageTitle = useMemo(() => {
    let pageTitle;
    if (title) {
      pageTitle = title;
    } else if (breadcrumbs?.length) {
      pageTitle = breadcrumbs[breadcrumbs.length - 1].title;
    }
    if (pageTitle) {
      return `${pageTitle} | ${APP_NAME}`;
    }
    return APP_NAME;
  }, [breadcrumbs, title]);

  const menuItems = useMemo<MenuItem[]>(
    () =>
      [...sections.userSectionList, ...sections.adminSectionList].map(({ title, icon, path: url }) => ({
        label: title,
        icon: icon as JSX.Element,
        onClick: (closeMenu) => {
          navigate(url);
          closeMenu();
        },
      })),
    [sections.userSectionList, sections.adminSectionList, navigate]
  );

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Header breadcrumbs={breadcrumbs} menuItems={menuItems} logo={{ title: APP_NAME }}></Header>
      <Toolbar />
      <Container
        sx={{
          flex: '1 1 auto',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'stretch',
          paddingY: 1,
          paddingX: 1.5,
          height: fullHeight ? 'calc(100vh - 64px)' : undefined,
          ...sx,
        }}
        maxWidth={error ? 'sm' : maxWidth}
        disableGutters
        {...containerProps}
      >
        {inProgress ? <FullPageProgress /> : error ? <ServerErrorBox error={error} /> : children}
      </Container>
    </>
  );
}

export const Layout = React.memo(LayoutComponent);
