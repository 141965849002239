import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/query';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { AuthGuard, useAuth, useReadPlantsQuery } from '@top-solution/microtecnica-utils';
import { UnauthorizedPage } from '..';
import { EditIcon, FileIcon } from '../../components/Icons';
import { RiskInlineBadgeLabel } from '../../components/InlineBadgeLabel/RiskInlineBadgeLabel';
import { Layout } from '../../components/Layout';
import { UserName } from '../../components/User';
import { REPORT_STATUS } from '../../entities/Report';
import { useReadReportByIdQuery } from '../../services/reportApi';
import { formatDateTime } from '../../utils/dates';
import { ReportHistoryDataGrid } from './ReportHistoryDataGrid';
import { ReportMenu } from './ReportMenu';
import { reportSection } from './sections';
import { getCategoryString } from './useReportCategoryDataGridColumn';

const breadcrumbs = [{ title: reportSection.title, url: reportSection.path }, { title: 'Dettaglio Report' }];

function DetailItem(props: { label: string; value?: string | React.ReactNode }): JSX.Element {
  return (
    <Box sx={{ display: 'flex' }}>
      <Typography variant="body1" sx={{ fontWeight: 700 }}>
        {props.label}:
      </Typography>
      <Typography variant="body1" ml={1} whiteSpace="nowrap" component="div" sx={{ whiteSpace: 'break-all' }}>
        {props.value ?? '-'}
      </Typography>
    </Box>
  );
}

function ReportDetailPage() {
  const params = useParams<{ id: string }>();
  const { data: report, isLoading, error } = useReadReportByIdQuery(Number(params.id) ?? skipToken);
  const { data: plants } = useReadPlantsQuery();
  const { isAdmin, hasRole, username } = useAuth();
  const navigate = useNavigate();

  return (
    <AuthGuard unauthorizedFallback={<UnauthorizedPage />}>
      <Layout sx={{ padding: 2 }} disableGutters error={error} breadcrumbs={breadcrumbs}>
        <Card>
          <CardHeader
            title={
              <Stack
                direction="row"
                alignItems="baseline"
                sx={{
                  marginBottom: 1,
                }}
              >
                <Typography component="h1" variant="h5">
                  {`Dettaglio report ${report?.title}`}
                </Typography>
                {report &&
                  report.username === username &&
                  (report.status === REPORT_STATUS.OPEN || report.status === REPORT_STATUS.REJECTED) && (
                    <IconButton
                      onClick={() => navigate(`/reports/${report.id}/edit`)}
                      aria-label="modifica"
                      sx={{ ml: 1 }}
                      size="small"
                    >
                      <EditIcon />
                    </IconButton>
                  )}

                <Box flex={1} />
                {report && (isAdmin || hasRole('user')) && <ReportMenu report={report} />}
              </Stack>
            }
          />
          <CardContent>
            {isLoading && (
              <Stack direction={{ xs: 'column' }} justifyContent="space-between" gap={1} mb={2}>
                <Skeleton sx={{ minWidth: 150, width: '30%', height: 80 }} />
                <Skeleton sx={{ minWidth: 150, width: '30%' }} />
                <Skeleton sx={{ minWidth: 150, width: '30%' }} />
                <Skeleton sx={{ minWidth: 150, width: '30%' }} />
                <Skeleton sx={{ minWidth: 150, width: '30%' }} />
                <Skeleton sx={{ minWidth: 150, width: '30%' }} />
                <Skeleton sx={{ minWidth: 150, width: '30%' }} />
                <Skeleton sx={{ minWidth: 150, width: '30%' }} />
              </Stack>
            )}
            {report && (
              <Stack
                sx={{
                  gap: 2,
                  flexDirection: 'column',
                  '.MuiStack-root': {
                    flexDirection: 'row',
                  },
                }}
              >
                <DetailItem label="Stabilimento" value={plants?.map[report.plantId].name} />
                <DetailItem label="ID" value={report.id.toString()} />
                <DetailItem label="Data Evento/Pericolo" value={formatDateTime(report.reportingDate)} />
                <DetailItem label="Data Segnalazione" value={formatDateTime(report.createdAt)} />
                <DetailItem label="Funzione di appartenenza" value={report.membershipFunction} />
                <DetailItem label="Severità del rischio" value={<RiskInlineBadgeLabel value={report.riskSeverity} />} />
                <DetailItem label="Stato" value={report.status} />
                <DetailItem label="Categoria" value={getCategoryString(report.category)} />
                <DetailItem label="Descrizione" value={report.description} />
                <DetailItem label="Descrizione rischio associato" value={report.associatedRiskDescription} />
                <DetailItem
                  label="Impatto sul cliente si o no (parte consegnata)"
                  value={report.isCustomerImpact ? 'Si' : 'No'}
                />
                <DetailItem
                  label="Contromisure già intraprese e suggerimenti per mitigazione rischio"
                  value={report.riskMitigation}
                />
                <DetailItem
                  label="Severità del potenziale rischio o evento occorso (proposta)"
                  value={report.potentialRiskElimination}
                />
                <DetailItem label="Luogo Evento" value={report.eventLocation} />
                <DetailItem label="Data creazione" value={formatDateTime(report.createdAt)} />
                <DetailItem label="Data aggiornamento" value={formatDateTime(report.updatedAt)} />
                <DetailItem
                  label="Segnalato da"
                  value={report.username ? <UserName username={report.username} /> : '-'}
                />
                <DetailItem
                  label="Già segnalato"
                  value={report.alreadyReported ? 'Si' : report.alreadyReported === false ? 'No' : '-'}
                />
                <DetailItem label="Anonimo" value={report.isAnonymous ? 'Si' : 'No'} />
                <DetailItem label="Tipologia report" value={report.reportType} />
                {isAdmin && <DetailItem label="Privato" value={report.isPublic === false ? 'Si' : 'No'} />}
                <Box mb={2}>
                  <Typography variant="body1" sx={{ fontWeight: 700 }}>
                    {'Allegati'}
                  </Typography>
                  <Box sx={{ display: 'flex' }} mb={3} mt={1}>
                    {(report.attachmentList ?? []).map((a) => (
                      <Chip
                        key={a.id}
                        label={a.name}
                        sx={{ marginRight: 1 }}
                        icon={<FileIcon />}
                        onClick={() => window.open(a.path, '_blank')?.focus()}
                      />
                    ))}
                  </Box>
                  <ReportHistoryDataGrid id={report.id} />
                </Box>
              </Stack>
            )}
          </CardContent>
        </Card>
      </Layout>
    </AuthGuard>
  );
}

export default React.memo(ReportDetailPage);
