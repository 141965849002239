import { useCallback } from 'react';
import { ConfirmDialogProps, DeleteConfirmDialog } from '@top-solution/microtecnica-mui';
import { ReportType } from '../../entities/Report';
import { useRemoveReportMutation } from '../../services/reportApi';

type ReportDeleteConfirmProps = Omit<ConfirmDialogProps, 'onClose' | 'title' | 'onConfirm'> & {
  data: ReportType;
  onClose: (value?: ReportType) => void;
  onConfirm?: () => void;
};

export function ReportDeleteConfirm(props: ReportDeleteConfirmProps): JSX.Element {
  const { data, onClose, TransitionProps, onConfirm, ...confirmProps } = props;
  const [remove, removeStatus] = useRemoveReportMutation();

  const handleConfirm = useCallback(async () => {
    await remove(data.id).unwrap();
    onConfirm && onConfirm();
    onClose(data);
  }, [remove, data, onConfirm, onClose]);

  return (
    <DeleteConfirmDialog
      confirmText="confermo"
      title="Eliminazione Report"
      description={`il report ${data.title} sarà eliminato`}
      inProgress={removeStatus.isLoading}
      error={removeStatus.error}
      onConfirm={handleConfirm}
      onClose={() => onClose()}
      TransitionProps={{
        ...TransitionProps,
        onExit: (node) => {
          removeStatus.reset();
          TransitionProps?.onExit?.(node);
        },
      }}
      {...confirmProps}
    />
  );
}
