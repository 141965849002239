import React, { useCallback, useMemo } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { skipToken } from '@reduxjs/toolkit/query';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import { ErrorAlert } from '@top-solution/microtecnica-mui';
import { AuthGuard, useReadPlantsQuery } from '@top-solution/microtecnica-utils';
import { FullPageProgress, Layout } from '../../components/Layout';
import { CompleteReportAddForm, CreateReportAddForm, QuickReportAddForm } from '../../entities/Report';
import { useNotifications } from '../../hooks/useNotifications';
import { useReadReportByIdQuery, useUpdateReportMutation } from '../../services/reportApi';
import UnauthorizedPage from '../Error/UnauthorizedPage';
import { isCompleteReportAddForm } from './CreateReportPage';
import CompleteReportForm from './ReportForm/CompleteReportForm';
import QuickReportForm from './ReportForm/QuickReportForm';
import { notifyEventSection } from './sections';

const breadcrumbs = [{ title: notifyEventSection.title, url: notifyEventSection.path }, { title: 'Modifica Report' }];

export default function EditReportPage(): React.ReactElement {
  const params = useParams<{ id: string }>();
  const { data: report, isLoading, error } = useReadReportByIdQuery(Number(params.id) ?? skipToken);
  const navigate = useNavigate();
  const { pushNotification } = useNotifications();
  const { data: plants } = useReadPlantsQuery();

  const [update, status] = useUpdateReportMutation();

  const handleSubmit = useCallback(
    async (data: CompleteReportAddForm | QuickReportAddForm) => {
      const parsedData = {
        ...data,
        reportingDate: data.reportingDate.toISOString(),
        isCustomerImpact: isCompleteReportAddForm(data)
          ? data.isCustomerImpact === 'unknown'
            ? null
            : data.isCustomerImpact === 'true'
          : undefined,
      } as unknown as CreateReportAddForm;
      await update({
        id: report!.id,
        ...parsedData,
        plantId: report!.plantId,
        attachmentList: isCompleteReportAddForm(data) ? data.attachmentList?.map((a) => a.id) : undefined,
      }).unwrap();
      pushNotification('Report modificato');
      navigate(`/reports/${report?.id}`);
    },
    [navigate, pushNotification, report, update]
  );

  const parsedInitialValues = useMemo(() => {
    if (report) {
      if (report.reportType)
        return {
          title: report.title,
          associatedRiskDescription: report.associatedRiskDescription,
          categoryId: report.category.id,
          description: report.description,
          membershipFunction: report.membershipFunction,
          reportingDate: new Date(report.reportingDate),
          riskMitigation: report.riskMitigation || '',
          riskSeverity: report.riskSeverity,
          alreadyReported: report.alreadyReported,
          flagTechData: false,
          isPublic: report.isPublic,
          isAnonymous: false,
          isCustomerImpact:
            report.isCustomerImpact === false ? 'false' : report.isCustomerImpact === true ? 'true' : 'unknown',
          attachmentList: report.attachmentList,
          reportType: report.reportType,
          potentialRiskElimination: report.potentialRiskElimination,
          eventLocation: report.eventLocation,
        };
      else
        return {
          title: report.title,
          associatedRiskDescription: report.associatedRiskDescription,
          categoryId: report.category.id,
          description: report.description,
          membershipFunction: report.membershipFunction,
          reportingDate: new Date(report.reportingDate),
          riskMitigation: report.riskMitigation || '',
          riskSeverity: report.riskSeverity,
          alreadyReported: report.alreadyReported,
          flagTechData: false,
        };
    }
    return undefined;
  }, [report]);

  if (isLoading) return <FullPageProgress />;

  return (
    <AuthGuard unauthorizedFallback={<UnauthorizedPage />}>
      <Layout sx={{ padding: 2 }} disableGutters breadcrumbs={breadcrumbs} error={error}>
        {report && (
          <>
            <Typography variant="h5">
              Report {report.title} - Stabilimento: {`${plants?.map[report.plantId].name}`}
            </Typography>
            {report.reportType ? (
              <CompleteReportForm
                onSubmit={handleSubmit}
                initialValues={parsedInitialValues as unknown as CompleteReportAddForm}
              />
            ) : (
              <QuickReportForm onSubmit={handleSubmit} initialValues={parsedInitialValues} />
            )}
            {status.error && <ErrorAlert error={status.error} />}
            <LoadingButton type="submit" form={'form'} variant="contained" loading={status.isLoading}>
              Invia
            </LoadingButton>
          </>
        )}
      </Layout>
    </AuthGuard>
  );
}
