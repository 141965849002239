import { ZodIssueCode, z } from 'zod';
import { AttachmentSchema } from './Attachment';
import { CategorySchema } from './Category';

export enum REPORT_STATUS {
  OPEN = 'aperto',
  ACCEPTED = 'accettato',
  ANALYSIS_IN_PROGRESS = 'analisi in corso',
  MANAGED_WITH_RISK = 'gestito con rischio',
  MITIGATION_IN_PROGRESS = 'mitigazione in corso',
  CLOSED = 'chiuso',
  REJECTED = 'rigettato',
}

export enum RISK_SEVERITY {
  ACCEPTABLE = 'accettabile',
  TO_BE_EVALUATED = 'da valutare',
  UNACCEPTABLE = 'inaccettabile',
}

export enum REPORT_TYPE {
  EVENT = 'Evento',
  HAZARD = 'Pericolo',
  ERROR = 'Errore',
  CHANGE = 'Cambiamento',
}

export const ReportSchema = z.object({
  id: z.number(),
  title: z.string().min(3),
  reportingDate: z.string().datetime(),
  membershipFunction: z.string().optional().nullable(),
  riskSeverity: z.nativeEnum(RISK_SEVERITY).optional().nullable(),
  status: z.nativeEnum(REPORT_STATUS),
  category: CategorySchema,
  description: z.string().min(50).max(600),
  associatedRiskDescription: z.string().optional().nullable(),
  riskMitigation: z.string().optional().nullable(),
  potentialRiskElimination: z.string().optional(),
  eventLocation: z.string().optional(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
  username: z.string().optional(),
  alreadyReported: z.boolean().optional().nullable(),
  isPublic: z.boolean().optional(),
  isAnonymous: z.boolean().optional(),
  reportType: z.nativeEnum(REPORT_TYPE).optional(),
  isCustomerImpact: z.boolean().optional().nullable(),
  attachmentList: z.array(AttachmentSchema).optional(),
  plantId: z.number(),
});

export const ReportHistorySchema = z.object({
  id: z.number(),
  status: z.nativeEnum(REPORT_STATUS),
  username: z.string().optional(),
  fromDate: z.string().datetime(),
  notes: z.string().optional(),
});

export const QuickReportAddFormSchema = ReportSchema.pick({
  title: true,
  membershipFunction: true,
  description: true,
  alreadyReported: true,
  riskSeverity: true,
  associatedRiskDescription: true,
}).extend({
  categoryId: z.number(),
  reportingDate: z.date().refine(
    (date) => {
      return date <= new Date();
    },
    {
      message: 'Non può essere nel futuro',
    }
  ),
  riskMitigation: z.string().min(3),
  flagTechData: z.boolean().refine((val) => val === true, {
    message: 'Conferma controllo dati tecnici',
  }),
});

export const CompleteReportAddFormSchema = ReportSchema.pick({
  title: true,
  membershipFunction: true,
  description: true,
  alreadyReported: true,
  associatedRiskDescription: true,
  isPublic: true,
  isAnonymous: true,
  attachmentList: true,
  potentialRiskElimination: true,
  eventLocation: true,
  riskSeverity: true,
})
  .extend({
    reportType: z.nativeEnum(REPORT_TYPE, {
      errorMap: (issue) => {
        switch (issue.code) {
          case 'invalid_type':
            return { message: 'Inserire tipologia segnalazione' };
          case 'invalid_enum_value':
            return { message: 'Inserire tipologia segnalazione' };
          default:
            return { message: 'Inserire tipologia segnalazione' };
        }
      },
    }),
    categoryId: z.number(),
    reportingDate: z.date().refine(
      (date) => {
        return date <= new Date();
      },
      {
        message: 'Non può essere nel futuro',
      }
    ),
    isCustomerImpact: z.enum(['true', 'false', 'unknown']).nullable().optional(),
    flagTechData: z.boolean().refine((val) => val === true, {
      message: 'Conferma controllo dati tecnici',
    }),
  })
  .superRefine((data, ctx) => {
    if (data.reportType === REPORT_TYPE.EVENT && (data.eventLocation?.length ?? 0) < 5) {
      ctx.addIssue({
        code: ZodIssueCode.too_small,
        minimum: 5,
        inclusive: true,
        type: 'string',
        path: ['eventLocation'],
        message: "Luogo dell'evento obbligatorio",
      });
    }
    if (data.reportType === REPORT_TYPE.EVENT && (data.potentialRiskElimination?.length ?? 0) < 5) {
      ctx.addIssue({
        code: ZodIssueCode.too_small,
        minimum: 5,
        inclusive: true,
        type: 'string',
        path: ['potentialRiskElimination'],
        message: 'Descrizione obbligatoria per tipologia evento',
      });
    }
    if (
      data.reportType === REPORT_TYPE.EVENT &&
      (data.isCustomerImpact === null || data.isCustomerImpact === undefined)
    ) {
      ctx.addIssue({
        code: ZodIssueCode.custom,
        path: ['isCustomerImpact'],
        message: 'Selezionare un valore per tipologia evento',
      });
    }
  });

export const CreateReportAddFormSchema = ReportSchema.omit({
  id: true,
  status: true,
  category: true,
  createdAt: true,
  updatedAt: true,
  username: true,
  attachmentList: true,
}).extend({
  categoryId: z.number(),
  attachmentList: z.array(z.number()).optional(),
});

export const UpdateReportStatusSchema = z
  .object({
    status: z.nativeEnum(REPORT_STATUS),
    notes: z.string(),
  })
  .superRefine((data, ctx) => {
    if (data.status === REPORT_STATUS.REJECTED && (data.notes?.length ?? 0) < 5) {
      ctx.addIssue({
        code: ZodIssueCode.too_small,
        minimum: 5,
        inclusive: true,
        type: 'string',
        path: ['notes'],
        message: 'Il commento è obbligatorio per questo stato e deve essere più lungo di 5 caratteri',
      });
    }
  });

export const ReportStatusFormSchema = ReportSchema.pick({
  status: true,
});

export type ReportType = z.infer<typeof ReportSchema>;
export type UpdateReportStatus = z.infer<typeof UpdateReportStatusSchema>;
export type ReportHistory = z.infer<typeof ReportHistorySchema>;
export type QuickReportAddForm = z.infer<typeof QuickReportAddFormSchema>;
export type CompleteReportAddForm = z.infer<typeof CompleteReportAddFormSchema>;
export type CreateReportAddForm = z.infer<typeof CreateReportAddFormSchema>;
export type ReportStatusForm = z.infer<typeof ReportStatusFormSchema>;
