import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import { GridColDef, GridRowModel, GridValueFormatterParams, GridValueGetterParams } from '@mui/x-data-grid-premium';
import { usePlantColDef } from '@top-solution/microtecnica-mui';
import { useAuth } from '@top-solution/microtecnica-utils';
import { userColumn } from '../../components/DataGrid/userColumn';
import { nonEmptyGridDateOperators } from '../../utils/datagrid';
import { formatDateTime } from '../../utils/dates';
import { useReportCategoryDataGridColumn } from './useReportCategoryDataGridColumn';
import { useReportRiskDataGridColumn } from './useReportRiskDataGridColumn';
import { useReportStatusDataGridColumn } from './useReportStatusDataGridColumn';
import { useReportTypeDataGridColumn } from './useReportTypeDataGridColumn';

export function useColumns(): GridColDef[] {
  const riskColumn = useReportRiskDataGridColumn();
  const statusColumn = useReportStatusDataGridColumn();
  const categoryColumn = useReportCategoryDataGridColumn();
  const typeColumn = useReportTypeDataGridColumn();
  const plantColumn = usePlantColDef();
  const { isAdmin, hasRole } = useAuth();
  const navigate = useNavigate();

  return useMemo<GridColDef[]>(
    () => [
      {
        headerName: 'ID',
        field: 'id',
        width: 60,
        type: 'number',
        renderCell: ({ row }) => (
          <Button size="small" color="primary" onClick={() => navigate(`/reports/${row.id}`)}>
            {row.id}
          </Button>
        ),
      },
      { ...plantColumn },
      {
        headerName: 'Titolo',
        field: 'title',
        width: 350,
        type: 'string',
      },
      {
        field: 'reportingDate',
        headerName: 'Data Evento/Pericolo',
        type: 'date',
        valueGetter: ({ value }: GridValueGetterParams<GridRowModel, string>) => value && new Date(value),
        valueFormatter: ({ value }: GridValueFormatterParams<Date>) => value && formatDateTime(value),
        width: 140,
        filterOperators: nonEmptyGridDateOperators(),
      },
      {
        headerName: 'Funzione di appartenenza',
        field: 'membershipFunction',
        width: 350,
        type: 'string',
      },
      { ...riskColumn },
      { ...statusColumn },
      { ...categoryColumn },
      {
        headerName: 'Descrizione',
        field: 'description',
        width: 350,
        type: 'string',
      },
      {
        headerName: 'Descrizione rischio associato',
        field: 'associatedRiskDescription',
        width: 350,
        type: 'string',
      },
      {
        headerName: 'Impatto sul cliente si o no (parte consegnata)',
        field: 'isCustomerImpact',
        width: 250,
        type: 'boolean',
      },
      {
        headerName: 'Contromisure già intraprese e suggerimenti per mitigazione rischio',
        field: 'riskMitigation',
        width: 350,
        type: 'string',
      },
      {
        headerName: 'Contromisure già intraprese e suggerimenti per eliminazione rischio potenziale',
        field: 'potentialRiskElimination',
        width: 350,
        type: 'string',
      },
      {
        headerName: 'Luogo evento',
        field: 'eventLocation',
        width: 350,
        type: 'string',
      },
      {
        field: 'createdAt',
        headerName: 'Creato',
        type: 'date',
        valueGetter: ({ value }: GridValueGetterParams<GridRowModel, string>) => value && new Date(value),
        valueFormatter: ({ value }: GridValueFormatterParams<Date>) => value && formatDateTime(value),
        width: 140,
        filterOperators: nonEmptyGridDateOperators(),
      },
      {
        field: 'updatedAt',
        headerName: 'Aggiornato',
        type: 'date',
        valueGetter: ({ value }: GridValueGetterParams<GridRowModel, string>) => value && new Date(value),
        valueFormatter: ({ value }: GridValueFormatterParams<Date>) => value && formatDateTime(value),
        width: 140,
        filterOperators: nonEmptyGridDateOperators(),
      },
      {
        ...userColumn,
        headerName: 'Riportato da',
        width: 200,
      },
      {
        headerName: 'Segnalazione ripetitiva',
        field: 'alreadyReported',
        width: 200,
        type: 'boolean',
      },
      {
        headerName: 'Anonimo',
        field: 'isAnonymous',
        width: 100,
        type: 'boolean',
      },
      { ...typeColumn },
      ...(isAdmin || hasRole('user')
        ? [
            {
              headerName: 'Privato',
              field: 'isPublic',
              width: 100,
              type: 'boolean',
              valueGetter: ({ value }: GridValueGetterParams<GridRowModel, boolean>) => value === false,
            },
          ]
        : []),
    ],
    [plantColumn, riskColumn, statusColumn, categoryColumn, typeColumn, isAdmin, hasRole, navigate]
  );
}
