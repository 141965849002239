import {
  GridFilterOperator,
  getGridDateOperators,
  getGridSingleSelectOperators,
  GridCellParams,
  GridFilterItem,
  GridColDef,
} from '@mui/x-data-grid-premium';

const checkFilter = ({ value }: GridFilterOperator) => {
  return value !== 'isEmpty' && value !== 'isNotEmpty' && value !== 'isAnyOf';
};

export const nonEmptyGridDateOperators = () =>
  getGridDateOperators()
    .filter(checkFilter)
    .filter((operator) => operator.value !== 'is' && operator.value !== 'not');

export function createMultipleValuesFilterOperators(
  singleValueGetter: (item: unknown) => string
): GridFilterOperator[] {
  return getGridSingleSelectOperators().map((operator) => {
    const getApplyFilterFn = (filterItem: GridFilterItem, column: GridColDef) => {
      if (!filterItem.field || !filterItem.value || !filterItem.operator || !column.filterable) {
        return null;
      }

      return (params: GridCellParams): boolean => {
        const value = params.value as unknown[] | undefined;
        if (operator.value === 'not') {
          return value?.findIndex((item) => singleValueGetter(item) === filterItem.value) === -1;
        }
        if (operator.value === 'isAnyOf') {
          if (value) {
            return (
              value.findIndex(
                (item) =>
                  filterItem.value.findIndex((filterValue: string) => filterValue === singleValueGetter(item)) > -1
              ) > -1
            );
          }
        }
        return (value && value?.findIndex((item) => singleValueGetter(item) === filterItem.value) > -1) ?? false;
      };
    };

    return {
      ...operator,
      getApplyFilterFn: getApplyFilterFn ?? operator.getApplyFilterFn,
    };
  });
}
