import { Route, Routes, useNavigate } from 'react-router-dom';
import { AuthCallback } from '@top-solution/microtecnica-utils';
import { Home, NotFoundPage, UnauthorizedPage } from './pages';
import CreateReportPage from './pages/Report/CreateReportPage';
import EditReportPage from './pages/Report/EditReportPage';
import NotifyEvent from './pages/Report/NotifyEvent';
import ReportDetailPage from './pages/Report/ReportDetailPage';
import ReportPage from './pages/Report/ReportPage';
import UserList from './pages/User/UserList';

export function App(): JSX.Element {
  const navigate = useNavigate();

  return (
    <Routes>
      <Route index element={<Home />} />
      <Route
        path="auth"
        element={
          <AuthCallback
            onAuthenticationSuccess={navigate}
            ErrorComponent={(props) => <UnauthorizedPage description={props.error} />}
          />
        }
      />
      <Route path="/reports" element={<ReportPage />} />
      <Route path="/reports/create" element={<CreateReportPage />} />
      <Route path="/reports/:id" element={<ReportDetailPage />} />
      <Route path="/reports/:id/edit" element={<EditReportPage />} />
      <Route path="/users" element={<UserList />} />
      <Route path="/notify-event" element={<NotifyEvent />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}
