import { z } from 'zod';

const baseCategorySchema = z.object({
  id: z.number(),
  name: z.string().min(1),
  isSelectable: z.boolean().optional(),
});

export type Category = z.infer<typeof baseCategorySchema> & {
  parent?: Category;
};

export const CategorySchema: z.ZodType<Category> = baseCategorySchema.extend({
  parent: z.lazy(() => CategorySchema.optional()),
});

export const CategoryResponseSchema = z.object({
  first: z.array(CategorySchema),
  second: z.array(CategorySchema),
  third: z.array(CategorySchema),
});

export type CategoryResponse = z.infer<typeof CategoryResponseSchema>;
