import { ArchiveIcon, BioHazardIcon } from '../../components/Icons';
import { AppSection } from '../../entities/AppSection';

export const reportSection: AppSection = {
  path: '/reports',
  title: 'Visibilità segnalazioni',
  icon: <ArchiveIcon />,
};

export const notifyEventSection: AppSection = {
  path: '/notify-event',
  title: 'Segnalare evento',
  icon: <BioHazardIcon />,
};
