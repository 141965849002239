import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';

function ThankYouBanner() {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography sx={{ textTransform: 'uppercase', fontWeight: 700 }} variant="h6">
        Safety management system
      </Typography>
      <Typography sx={{ textTransform: 'uppercase', fontWeight: 700 }} variant="h6">
        Politica di sicurezza
      </Typography>
      <Typography sx={{ my: 1 }}>
        <span style={{ fontWeight: 700 }}>{`Incoraggiamo l'identificazione e la segnalazione volontaria`}</span>
        {` di errori, pericoli, incidenti o problemi di sicurezza utilizzando gli strumenti forniti: tutti i dipendenti a conoscenza di un prodotto non conforme o di una condizione non sicura o di un potenziale pericolo per la sicurezza dei nostri prodotti possono segnalarlo attraverso i canali forniti.`}
      </Typography>
      <Typography>Torino, 7 Maggio 2024</Typography>
      <div style={{ display: 'flex', justifyContent: 'end' }}>
        <img style={{ maxWidth: '250px' }} src="/signature.jpg" />
      </div>
    </Box>
  );
}

export function ThankYouDialog(props: DialogProps & { onClose: () => void }): JSX.Element {
  const { onClose, ...dialogProps } = props;

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      {...dialogProps}
      TransitionProps={{
        ...dialogProps.TransitionProps,
        onExit: (event) => {
          dialogProps.TransitionProps?.onExit?.(event);
        },
      }}
    >
      <DialogTitle>Conferma avvenuta presa in carico</DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant="h6" sx={{ mb: 3 }}>
          Ti ringraziamo per la segnalazione, una notifica è stata inviata al gruppo preposto per la gestione.
        </Typography>
        <ThankYouBanner />
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={() => onClose()}>
          Chiudi
        </Button>
      </DialogActions>
    </Dialog>
  );
}
