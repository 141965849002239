import { useMemo, useRef } from 'react';
import Box from '@mui/material/Box';
import { GridColDef, GridFilterInputValueProps, GridFilterItem, GridFilterOperator } from '@mui/x-data-grid-premium';
import { Category } from '../../entities/Category';
import { ReportType } from '../../entities/Report';
import CategorySelect from './ReportForm/CategorySelect';

function CategoryInputValue(props: GridFilterInputValueProps) {
  const { item, applyValue } = props;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const inputRef: React.Ref<any> = useRef(null);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleFilterChange = (event: any, newValue: any) => {
    applyValue({ ...item, value: newValue });
  };

  return (
    <Box
      sx={{
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <CategorySelect value={item.value} onChange={handleFilterChange} ref={inputRef} />
    </Box>
  );
}

const categoryOperators: GridFilterOperator[] = [
  {
    label: 'Appartiene',
    value: 'is',
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (!filterItem.field || !filterItem.value || !filterItem.operator) {
        return null;
      }

      return (params): boolean => {
        return Number(params.value) == Number(filterItem.value);
      };
    },
    InputComponent: CategoryInputValue,
    InputComponentProps: { type: 'number' },
  },
];

export function getCategoryString(category: Category): string {
  if (category.parent) return `${getCategoryString(category.parent)} -> ${category.name}`;
  return category.name;
}

export function useReportCategoryDataGridColumn(): GridColDef<ReportType> {
  return useMemo(
    () => ({
      field: 'category',
      headerName: 'Categoria',
      valueGetter: ({ row }) => {
        return getCategoryString(row.category);
      },
      width: 450,
      filterOperators: categoryOperators,
    }),
    []
  );
}
