import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from './store';

type NotificationsItem = {
  id: string;
  message: string;
  closing: boolean;
};

type notificationsSlice = NotificationsItem[];

const initialState: notificationsSlice = [];

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    pushNotification: (state, action: PayloadAction<{ message: string }>) => {
      state.push({
        id: `${action.payload.message}-${new Date().toISOString()}`,
        message: action.payload.message,
        closing: false,
      });
    },
    closeNotification: (state, action: PayloadAction<{ index: number }>) => {
      if (state[action.payload.index]) {
        state[action.payload.index].closing = true;
      }
    },
    removeNotification: (state, action: PayloadAction<{ index: number }>) => {
      state.splice(action.payload.index, 1);
    },
  },
});

export const { pushNotification, closeNotification, removeNotification } = notificationsSlice.actions;

export const selectNotifications = (state: RootState) => state.notifications ?? [];

export default notificationsSlice.reducer;
