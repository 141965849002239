import { useMemo } from 'react';
import { GridSingleSelectColDef } from '@mui/x-data-grid-premium';
import { REPORT_TYPE } from '../../entities/Report';

export function useReportTypeDataGridColumn(): GridSingleSelectColDef {
  const valueOptions = Object.values(REPORT_TYPE);
  return useMemo(
    () => ({
      field: 'reportType',
      headerName: 'Tipologia report',
      type: 'singleSelect',
      width: 150,
      valueOptions,
    }),
    [valueOptions]
  );
}
