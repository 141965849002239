import { useCallback, useMemo, useState } from 'react';
import { GridFilterModel, GridLogicOperator, GridPinnedColumns, GridSortModel } from '@mui/x-data-grid-premium';
import {
  DataGrid,
  DataGridProps,
  ErrorAlert,
  GridToolbarExportAll,
  muiFiltersToPagedRequestFilters,
  usePagination,
} from '@top-solution/microtecnica-mui';
import { useLazyReadReportListQuery, useReadReportListQuery } from '../../services/reportApi';
import { useColumns } from './columns';

type EUSListDataGridProps = Omit<
  DataGridProps,
  | 'rows'
  | 'columns'
  | 'loading'
  | 'error'
  | 'pagination'
  | 'paginationMode'
  | 'rowCount'
  | 'page'
  | 'onPageChange'
  | 'pageSize'
  | 'onPageSizeChange'
  | 'sortingMode'
  | 'sortModel'
  | 'onSortModelChange'
  | 'filterMode'
  | 'onFilterModelChange'
  | 'filterModel'
> & {
  columns?: DataGridProps['columns'];
};

const pinnedColumns: GridPinnedColumns = { right: ['actions'] };

export function ReportDataGrid(props: EUSListDataGridProps) {
  const { columns, ...gridProps } = props;
  const defaultColumns = useColumns();

  const { paginationModel, setPaginationModel } = usePagination(0);
  const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'id', sort: 'asc' }]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [], logicOperator: GridLogicOperator.And });
  const readReportListParams = useMemo(
    () => ({
      limit: paginationModel.pageSize,
      offset: paginationModel.pageSize * paginationModel.page,
      sort: sortModel.map(({ field, sort }) => `${sort === 'desc' ? '-' : ''}${field}`),
      filters: muiFiltersToPagedRequestFilters(filterModel.items),
    }),
    [filterModel.items, paginationModel.page, paginationModel.pageSize, sortModel]
  );

  const reportList = useReadReportListQuery(readReportListParams, { skip: paginationModel.pageSize === undefined });

  const handleSortModelChange = useCallback(
    (sortModel: GridSortModel) => {
      setSortModel(sortModel);
      setPaginationModel({ page: 0 });
    },
    [setPaginationModel]
  );

  const handleFilterModelChange = useCallback(
    (filterModel: GridFilterModel) => {
      setFilterModel(filterModel);
      setPaginationModel({ page: 0 });
    },
    [setPaginationModel]
  );

  function ExportAllToolbar() {
    const [readList] = useLazyReadReportListQuery();

    return (
      <GridToolbarExportAll
        onRowsRequest={async () => {
          const res = await readList({
            ...readReportListParams,
            limit: 10 ** 5,
            offset: 0,
          }).unwrap();

          return res.data;
        }}
      />
    );
  }

  if (reportList.error) {
    return <ErrorAlert error={reportList.error} />;
  }

  return (
    <DataGrid
      density="compact"
      rows={reportList.data?.data ?? []}
      columns={columns ?? defaultColumns}
      sessionStorageId="EUSListPageDataGrid"
      loading={reportList.isFetching}
      pinnedColumns={pinnedColumns}
      pagination
      paginationMode="server"
      rowCount={reportList.data?.total ?? 0}
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      sortingMode="server"
      sortModel={sortModel}
      onSortModelChange={handleSortModelChange}
      filterMode="server"
      filterModel={filterModel}
      disableAggregation
      disableRowGrouping
      onFilterModelChange={handleFilterModelChange}
      slots={{ footer: ExportAllToolbar }}
      slotProps={{
        filterPanel: {
          sx: {
            '.MuiDataGrid-filterFormValueInput': {
              width: '600px',
            },
          },
        },
      }}
      {...gridProps}
    />
  );
}
