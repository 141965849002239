import React from 'react';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { CancelIcon } from '../../../components/Icons';
import { RiskInlineBadgeLabel } from '../../../components/InlineBadgeLabel/RiskInlineBadgeLabel';
import { RISK_SEVERITY } from '../../../entities/Report';

export interface RiskSeveritySelectProps extends Omit<TextFieldProps, 'value' | 'onChange'> {
  value?: string | null;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLButtonElement>,
    value: string | null
  ) => void;
}

function RiskSeveritySelect(props: RiskSeveritySelectProps, ref: React.Ref<HTMLDivElement>) {
  const { value, onChange, ...otherProps } = props;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e, e.target.value ?? null);
    }
  };

  return (
    <TextField
      label="Severità del rischio (proposta)"
      variant="outlined"
      select
      {...otherProps}
      SelectProps={{ ref }}
      onChange={handleChange}
      value={value || ''}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end" style={{ marginRight: 16 }}>
            {Boolean(value) && onChange && (
              <IconButton onClick={(e) => onChange(e, null)} edge="end" size="small">
                <CancelIcon />
              </IconButton>
            )}
          </InputAdornment>
        ),
      }}
    >
      {Object.values(RISK_SEVERITY).map((r) => (
        <MenuItem key={r} value={r}>
          <RiskInlineBadgeLabel value={r} />
        </MenuItem>
      ))}
    </TextField>
  );
}

export default React.forwardRef(RiskSeveritySelect);
