import { useCallback, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { ErrorAlert } from '@top-solution/microtecnica-mui';
import { SaveIcon } from '../../../components/Icons';
import { REPORT_STATUS, ReportType, UpdateReportStatus, UpdateReportStatusSchema } from '../../../entities/Report';
import { useUpdateStatusMutation } from '../../../services/reportApi';

export function ReportStatusDialog(props: DialogProps & { report: ReportType; onClose: () => void }): JSX.Element {
  const { report, onClose, ...dialogProps } = props;
  const [update, updateStatus] = useUpdateStatusMutation();
  const statusList = useMemo(() => Object.values(REPORT_STATUS), []);

  const form = useForm<UpdateReportStatus>({
    defaultValues: { notes: '', status: report.status },
    resolver: zodResolver(UpdateReportStatusSchema),
  });
  const { control } = form;

  const onSubmit = useCallback(
    async (data: UpdateReportStatus) => {
      await update({
        ...data,
        id: report.id,
      }).unwrap();

      onClose();
    },
    [update, report.id, onClose]
  );

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      {...dialogProps}
      TransitionProps={{
        ...dialogProps.TransitionProps,
        onExit: (event) => {
          updateStatus.reset();
          form.reset();
          dialogProps.TransitionProps?.onExit?.(event);
        },
      }}
    >
      <form onSubmit={form.handleSubmit(onSubmit)} noValidate>
        <DialogTitle>Aggiorna Stato Report:{`${report.title}`}</DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', '.MuiFormControl-root': { mt: 1 } }}>
          <Controller
            control={control}
            name="status"
            render={({ field: { onChange, ref, ...field }, fieldState: { error } }) => (
              <TextField
                label="Stato"
                onChange={(e) => onChange(e.target.value)}
                select
                SelectProps={{ ref }}
                error={Boolean(error)}
                helperText={error?.message || ' '}
                {...field}
              >
                {statusList.map((s) => (
                  <MenuItem key={s} value={s}>
                    {s}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />

          <Controller
            control={control}
            name="notes"
            render={({ field, fieldState: { error } }) => (
              <TextField
                label="Note"
                error={Boolean(error)}
                helperText={error?.message || ' '}
                sx={{ flex: 1 }}
                {...field}
              />
            )}
          />

          {updateStatus.error && <ErrorAlert error={updateStatus.error} />}
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={() => onClose()}>
            Annulla
          </Button>
          <LoadingButton
            type="submit"
            color="primary"
            variant="contained"
            loading={updateStatus.isLoading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
          >
            Salva
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}
