import { useMemo } from 'react';
import { GridSingleSelectColDef } from '@mui/x-data-grid-premium';
import { REPORT_STATUS } from '../../entities/Report';

export function useReportStatusDataGridColumn(): GridSingleSelectColDef {
  const valueOptions = Object.values(REPORT_STATUS);
  return useMemo(
    () => ({
      field: 'status',
      headerName: 'Stato',
      type: 'singleSelect',
      valueOptions,
      width: 150,
    }),
    [valueOptions]
  );
}
