import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { authSliceReducer, commonDataApi } from '@top-solution/microtecnica-utils';
import { api } from '../services/baseApi';
import notificationsReducer from './notificationsSlice';

export const store = configureStore({
  reducer: {
    auth: authSliceReducer,
    notifications: notificationsReducer,
    [api.reducerPath]: api.reducer,
    [commonDataApi.reducerPath]: commonDataApi.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware, commonDataApi.middleware),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
