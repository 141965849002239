import React, { useCallback, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import { ErrorAlert } from '@top-solution/microtecnica-mui';
import { AuthGuard, useReadPlantsQuery } from '@top-solution/microtecnica-utils';
import { Layout } from '../../components/Layout';
import { CompleteReportAddForm, QuickReportAddForm } from '../../entities/Report';
import { useCreateReportMutation } from '../../services/reportApi';
import UnauthorizedPage from '../Error/UnauthorizedPage';
import CompleteReportForm from './ReportForm/CompleteReportForm';
import QuickReportForm from './ReportForm/QuickReportForm';
import { ThankYouDialog } from './ReportForm/ThankYouDialog';
import { notifyEventSection } from './sections';

const breadcrumbs = [
  { title: notifyEventSection.title, url: notifyEventSection.path },
  { title: 'Compilazione Report' },
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isCompleteReportAddForm(obj: any): obj is CompleteReportAddForm {
  return !!obj.reportType;
}

export default function CreateReportPage(): React.ReactElement {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const plantId = queryParams.get('plantId');
  const isComplete = queryParams.get('complete') === 'true';
  const navigate = useNavigate();
  const [createdReportId, setCreatedReportId] = useState<number | null>(null);
  const { data: plants } = useReadPlantsQuery();

  const [create, status] = useCreateReportMutation();

  const handleSubmit = useCallback(
    async (data: CompleteReportAddForm | QuickReportAddForm) => {
      if (!plantId) return;
      const parsedData = {
        ...data,
        plantId: Number(plantId),
        reportingDate: data.reportingDate.toISOString(),
        attachmentList: isCompleteReportAddForm(data) ? data.attachmentList?.map((a) => a.id) : undefined,
        isCustomerImpact: isCompleteReportAddForm(data)
          ? data.isCustomerImpact === 'unknown'
            ? null
            : data.isCustomerImpact === 'true'
          : undefined,
      };
      if (parsedData.isCustomerImpact === null) delete parsedData.isCustomerImpact;
      const id = await create(parsedData).unwrap();
      setCreatedReportId(id);
    },
    [create, plantId]
  );

  return (
    <AuthGuard unauthorizedFallback={<UnauthorizedPage />}>
      <Layout sx={{ padding: 2 }} disableGutters breadcrumbs={breadcrumbs}>
        {!plantId ? (
          <ErrorAlert error={new Error('Nessun plant selezionato ')} />
        ) : (
          <Typography variant="h5">
            Report {isComplete ? 'completo' : 'veloce'} - Stabilimento: {`${plants?.map[plantId!].name}`}
          </Typography>
        )}

        {isComplete ? <CompleteReportForm onSubmit={handleSubmit} /> : <QuickReportForm onSubmit={handleSubmit} />}
        {status.error && <ErrorAlert error={status.error} />}
        <LoadingButton type="submit" form={'form'} variant="contained" loading={status.isLoading}>
          Invia
        </LoadingButton>
        <ThankYouDialog
          open={!!createdReportId}
          onClose={() => {
            navigate(`/reports/${createdReportId}`);
            setCreatedReportId(null);
          }}
        />
      </Layout>
    </AuthGuard>
  );
}
