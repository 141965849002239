import { useMemo } from 'react';
import { z } from 'zod';
import { CompleteReportAddFormSchema, QuickReportAddFormSchema } from '../../../entities/Report';
import { useReadReportTaxonomyQuery } from '../../../services/reportApi';

export function useReportAddFormSchemaWithCategoryCheck(isComplete: boolean) {
  const { data } = useReadReportTaxonomyQuery();

  const ReportAddFormSchemaWithCategoryCheck = useMemo(() => {
    if (isComplete)
      return CompleteReportAddFormSchema.superRefine((val, ctx) => {
        if (val.categoryId && data) {
          const category = [...data.first, ...data.second, ...data.third].find((c) => c.id === val.categoryId);
          if (!category?.isSelectable) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Selezionare il livello annidato',
              path: ['categoryId'],
            });
          }
        }
      });
    else
      return QuickReportAddFormSchema.superRefine((val, ctx) => {
        if (val.categoryId && data) {
          const category = [...data.first, ...data.second, ...data.third].find((c) => c.id === val.categoryId);
          if (!category?.isSelectable) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Selezionare il livello annidato',
              path: ['categoryId'],
            });
          }
        }
      });
  }, [data, isComplete]);

  return { ReportAddFormSchemaWithCategoryCheck };
}
