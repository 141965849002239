import { useMemo } from 'react';
import { GridRenderCellParams, GridSingleSelectColDef } from '@mui/x-data-grid-premium';
import { RiskInlineBadgeLabel } from '../../components/InlineBadgeLabel/RiskInlineBadgeLabel';
import { RISK_SEVERITY, ReportType } from '../../entities/Report';

export function useReportRiskDataGridColumn(): GridSingleSelectColDef {
  const valueOptions = Object.values(RISK_SEVERITY);
  return useMemo(
    () => ({
      field: 'riskSeverity',
      headerName: 'Severità del rischio',
      type: 'singleSelect',
      valueOptions,
      renderCell: ({ value }: GridRenderCellParams<ReportType, RISK_SEVERITY>) => (
        <RiskInlineBadgeLabel value={value} />
      ),
    }),
    [valueOptions]
  );
}
