import React, { useMemo } from 'react';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { CancelIcon } from '../../../components/Icons';

export interface AlreadyReportedSelectProps extends Omit<TextFieldProps, 'value' | 'onChange'> {
  value?: boolean | null;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLButtonElement>,
    value: boolean | null
  ) => void;
}

function AlreadyReportedSelect(props: AlreadyReportedSelectProps, ref: React.Ref<HTMLDivElement>) {
  const { value, onChange, ...otherProps } = props;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e, e.target.value === 'true' ? true : e.target.value === 'false' ? false : null);
    }
  };

  const convertedValue = useMemo(() => {
    if (value === true) return 'true';
    if (value === false) return 'false';
    return null;
  }, [value]);

  return (
    <TextField
      label="Segnalazione ripetitiva"
      variant="outlined"
      select
      {...otherProps}
      onChange={handleChange}
      SelectProps={{ ref }}
      value={convertedValue}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end" style={{ marginRight: 16 }}>
            {Boolean(convertedValue) && onChange && (
              <IconButton onClick={(e) => onChange(e, null)} edge="end" size="small">
                <CancelIcon />
              </IconButton>
            )}
          </InputAdornment>
        ),
      }}
    >
      <MenuItem value={'false'}>{'No'}</MenuItem>
      <MenuItem value={'true'}>{'Si'}</MenuItem>
    </TextField>
  );
}

export default React.forwardRef(AlreadyReportedSelect);
