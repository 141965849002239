import React from 'react';

import Box from '@mui/material/Box';

import { cyan } from '@mui/material/colors';
import Paper from '@mui/material/Paper';

import Typography from '@mui/material/Typography';
import { InfoTooltip, InfoTooltipProps } from '../../../components/InfoTooltip';

function InfoTooltipTypeComponent(props: InfoTooltipProps) {
  return (
    <InfoTooltip {...props}>
      <Paper elevation={0}>
        <Box
          sx={{
            '> div': {
              padding: 1,
              border: '1px solid',
            },
            display: 'grid',
            width: '100%',
            height: '100%',
            gridTemplateAreas: `"header header"
                                      "left1 main1"
                                      "left2 main2"
                                      "left3 main3"
                                      "left4 main4"
                                      "left5 main5"
                                      `,
            gridTemplateColumns: '1fr 4fr',
            gridTemplateRows: '110px 1fr repeat(4, auto)',
            border: '1px solid',
          }}
        >
          <div style={{ gridArea: 'header', backgroundColor: cyan[200] }}>
            <Typography style={{ textAlign: 'center' }} variant="h6">
              DEFINIZIONI UTILI
            </Typography>
            <Typography>
              {`Ref. EASA "Continuing airworthiness"`}{' '}
              {`(Annex II, GENERAL), "Airworthiness Certification"
                (Annex I, Definitions)`}
            </Typography>
          </div>

          <div style={{ gridArea: 'left1' }}>
            <Typography>Errore (error):</Typography>
          </div>
          <div style={{ gridArea: 'main1' }}>
            <Typography>
              {`É un'azione o inazione involontaria da parte di una persona che può portare a deviazioni dalle
                      procedure accettate o regolamenti. Nota: gli errori sono spesso associati a occasioni in cui una
                      sequenza pianificata di attività mentali o le attività fisiche non riescono a raggiungere il
                      risultato previsto o non sono appropriate riguardo al risultato atteso e quando i risultati non
                      possono essere attribuiti puramente al caso. Esempio: un operatore, poco prima di iniziare l'ATP,
                      si accorge di aver impostato i valori richiesti in maniera mnemonica senza aver verificato il
                      requisito prima di operare.`}
            </Typography>
          </div>
          <div style={{ gridArea: 'left2' }}>
            <Typography>Pericolo (Hazard):</Typography>
          </div>
          <div style={{ gridArea: 'main2' }}>
            <Typography>
              {`É una condizione o un oggetto che può potenzialmente causare o contribuire ad un incidente aereo o
                      ad un incidente. Si ricorda che un pericolo (hazard) è una condizione intrinseca del sistema
                      organizzativo e che, pertanto, non può essere eliminata. Esempio: banco di lavoro MRO, mancanza
                      controllo FOD / Produzione OE, ciclo di lavoro con disegni non aggiornati.`}
            </Typography>
          </div>
          <div style={{ gridArea: 'left3' }}>
            <Typography>Mancato evento (near miss):</Typography>
          </div>
          <div style={{ gridArea: 'main3' }}>
            <Typography>
              {`definito anche "quasi evento", è un avvenimento che è stato scongiurato o evitato e la parte NON è
                      stata consegnata al Cliente. Esempio: un operatore, ricontrollando il suo lavoro alla fine di
                      un'operazione prima di consegnarlo alla fase successiva, si accorge che uno step non è stato
                      eseguito correttamente.`}
            </Typography>
          </div>
          <div style={{ gridArea: 'left4' }}>
            <Typography>Evento (Occurrence):</Typography>
          </div>
          <div style={{ gridArea: 'main4' }}>
            <Typography>
              {`É un avvenimento che deve essere obbligatoriamente segnalato ai sensi del Regolamento (UE) n.
                      376/2014, in quanto la parte NON CONFORME è stata consegnata al Cliente. Esempio: un operatore,
                      eseguendo un ATP su una parte, si accorge che il Test Set è scaduto e, dopo verifica, ci si
                      accorge che altre parti sono già state consegnate.`}
            </Typography>
          </div>
          <div style={{ gridArea: 'left5' }}>
            <Typography>Cambiamento:</Typography>
          </div>
          <div style={{ gridArea: 'main5' }}>
            <Typography>
              {`É una variazione del sistema organizzativo e operativo nel quale si intravedono nuovi pericoli
                      o rischi. Esempio: un nuovo Regolamento, un nuovo Prodotto, un nuovo fornitore, un nuovo Tool/Test
                      bench, una nuova procedura, un nuovo gruppo di operatori tecnici, un nuovo modo di packaging...`}
            </Typography>
          </div>
        </Box>
      </Paper>
    </InfoTooltip>
  );
}

export const InfoTooltipType = React.memo(InfoTooltipTypeComponent);
