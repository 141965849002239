import React, { useMemo, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { GridActionsCellItem, GridColDef, GridRowParams, MuiEvent } from '@mui/x-data-grid-premium';
import { DataGridWrapper } from '@top-solution/microtecnica-mui';
import { AuthGuard, useAuth } from '@top-solution/microtecnica-utils';
import { AddIcon, DeleteIcon, DetailsIcon, EditIcon, StateMachineIcon } from '../../components/Icons';
import { Layout } from '../../components/Layout';
import { REPORT_STATUS, ReportType } from '../../entities/Report';
import UnauthorizedPage from '../Error/UnauthorizedPage';
import { ReportStatusDialog } from './ChooseStatusDialog/ReportStatusDialog';
import { useColumns } from './columns';
import { ReportDataGrid } from './ReportDataGrid';
import { ReportDeleteConfirm } from './ReportDeleteConfirm';
import { reportSection } from './sections';

const breadcrumbs = [{ title: reportSection.title }];
export default function ReportPage(): React.ReactElement {
  const { username, isAdmin, hasRole } = useAuth();
  const navigate = useNavigate();
  const reportColumns = useColumns();

  const [reportToUpdateStatus, setReportToUpdateStatus] = useState<ReportType | null>(null);
  const [reportToDelete, setReportToDelete] = useState<ReportType | null>(null);

  const handleDetailClick = useCallback(
    ({ row }: GridRowParams<ReportType>) =>
      (event: MuiEvent<React.MouseEvent>) => {
        event.stopPropagation();
        navigate(`/reports/${row.id}`);
      },
    [navigate]
  );

  const handleEditClick = useCallback(
    ({ row }: GridRowParams<ReportType>) =>
      (event: MuiEvent<React.MouseEvent>) => {
        event.stopPropagation();
        navigate(`/reports/${row.id}/edit`);
      },
    [navigate]
  );

  const handleStatusUpdateClick = useCallback(
    ({ row }: GridRowParams<ReportType>) =>
      (event: MuiEvent<React.MouseEvent>) => {
        event.stopPropagation();
        setReportToUpdateStatus(row);
      },
    []
  );

  const handleDeleteClick = useCallback(
    ({ row }: GridRowParams<ReportType>) =>
      (event: MuiEvent<React.MouseEvent>) => {
        event.stopPropagation();
        setReportToDelete(row);
      },
    []
  );

  const columns = useMemo<GridColDef[]>(
    () => [
      ...reportColumns,
      {
        field: 'actions',
        type: 'actions',
        width: 150,
        renderHeader: () => (
          <Tooltip title="Aggiungi Report">
            <IconButton
              color="primary"
              onClick={() => {
                navigate('/notify-event');
              }}
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
        ),
        getActions: (params: GridRowParams<ReportType>) => [
          <GridActionsCellItem
            icon={<DetailsIcon />}
            key="view"
            label="Dettaglio"
            onClick={handleDetailClick(params)}
          />,
          isAdmin || hasRole('user') ? (
            <>
              <GridActionsCellItem
                icon={<StateMachineIcon />}
                key="edit-status"
                label="Modifica Status"
                onClick={handleStatusUpdateClick(params)}
              />
              <GridActionsCellItem
                icon={<DeleteIcon />}
                key="delete"
                label="Elimina"
                onClick={handleDeleteClick(params)}
              />
            </>
          ) : (
            <></>
          ),
          params.row.username === username &&
          (params.row.status === REPORT_STATUS.OPEN || params.row.status === REPORT_STATUS.REJECTED) ? (
            <GridActionsCellItem icon={<EditIcon />} key="edit" label="Modifica" onClick={handleEditClick(params)} />
          ) : (
            <></>
          ),
        ],
      },
    ],
    [
      handleDeleteClick,
      handleDetailClick,
      handleEditClick,
      handleStatusUpdateClick,
      hasRole,
      isAdmin,
      navigate,
      reportColumns,
      username,
    ]
  );

  return (
    <AuthGuard unauthorizedFallback={<UnauthorizedPage />}>
      <Layout sx={{ padding: 2 }} disableGutters breadcrumbs={breadcrumbs} maxWidth={false}>
        <DataGridWrapper>
          <ReportDataGrid columns={columns} sessionStorageId={`ReportListDataGrid`} />
        </DataGridWrapper>
        {reportToDelete && (
          <ReportDeleteConfirm open={!!reportToDelete} onClose={() => setReportToDelete(null)} data={reportToDelete} />
        )}
        {reportToUpdateStatus && (
          <ReportStatusDialog
            open={!!reportToUpdateStatus}
            onClose={() => setReportToUpdateStatus(null)}
            report={reportToUpdateStatus}
          />
        )}
      </Layout>
    </AuthGuard>
  );
}
