import React, { useState } from 'react';

import Button, { ButtonProps } from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import Tooltip, { TooltipProps } from '@mui/material/Tooltip';

import { InformationOutlineIcon } from './Icons';

export interface InfoTooltipProps extends ButtonProps {
  tooltipProps?: TooltipProps;
  children?: React.ReactNode;
}

function InfoTooltipComponent(props: InfoTooltipProps) {
  const { tooltipProps, children, ...buttonProps } = props;
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <Tooltip
          open={open}
          title={children}
          arrow
          placement="bottom"
          PopperProps={{
            disablePortal: true,
          }}
          componentsProps={{
            tooltip: {
              style: {
                backgroundColor: 'white',
                maxWidth: '800px',
                border: 0,
              },
            },
          }}
          onClose={handleTooltipClose}
          disableFocusListener
          disableHoverListener
          {...tooltipProps}
        >
          <Button
            aria-label="info"
            variant="contained"
            color="info"
            size="small"
            disableElevation
            onClick={() => setOpen((o) => !o)}
            {...buttonProps}
            sx={{
              minWidth: 0,
              width: 28,
              height: 28,
              padding: 0,
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              ...buttonProps.sx,
            }}
          >
            <InformationOutlineIcon sx={{ width: 24, height: 24 }} />
          </Button>
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
}

export const InfoTooltip = React.memo(InfoTooltipComponent);
