import React from 'react';
import { RISK_SEVERITY } from '../../entities/Report';
import { capitalize } from '../../utils/strings';
import { InlineBadgeLabel } from './InlineBadgeLabel';

function RiskInlineBadgeLabelComponent(props: { value?: RISK_SEVERITY | null }) {
  const { value } = props;
  if (!value) return <></>;
  return (
    <InlineBadgeLabel
      badgeColor={
        value === RISK_SEVERITY.ACCEPTABLE ? 'success' : value === RISK_SEVERITY.UNACCEPTABLE ? 'error' : 'warning'
      }
    >
      {capitalize(value)}
    </InlineBadgeLabel>
  );
}

export const RiskInlineBadgeLabel = React.memo(RiskInlineBadgeLabelComponent);
